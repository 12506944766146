import { Query, ResultSet } from "@cubejs-client/core";
import { config } from "@src/config";
import { useExploreContext } from "@src/contexts/explore-context";
import { message } from "antd";
import React, { useRef } from "react";

export const useChartResult = () => {
  const { query, cubeApi } = useExploreContext();

  const [messageApi, contextHolder] = message.useMessage();

  const [pageSize, setPageSize] = React.useState(config.DEFAULT_PAGE_SIZE);
  const [page, setPage] = React.useState(1);
  const [resultSet, setResultSet] = React.useState<ResultSet<any>>();
  const [isLoading, setIsLoading] = React.useState(false);
  const mutexRef = useRef({});

  const resetPagination = () => {
    setPage(1);
    setPageSize(config.DEFAULT_PAGE_SIZE);
  };

  const refetchResult = async ({ offset, limit }: { offset: number; limit: number }) => {
    setIsLoading(true);
    const result = await cubeApi?.load(
      {
        ...query,
        limit: limit,
        offset: offset,
        // total: true,
      },
      {
        mutexObj: mutexRef.current,
        mutexKey: "query",
      },
    );
    setIsLoading(false);
    setResultSet(result);

    return result;
  };

  const [previousQuery, setPreviousQuery] = React.useState<Query>();

  const isQueryChanging = JSON.stringify(query) !== JSON.stringify(previousQuery);

  const onRefresh = () => {
    resetPagination();
    setPreviousQuery(JSON.parse(JSON.stringify(query)));
    refetchResult({ offset: 0, limit: config.DEFAULT_PAGE_SIZE });
  };
  return {
    pageSize,
    setPageSize,
    page,
    setPage,
    resultSet,
    isLoading,
    isQueryChanging,
    onRefresh,
    refetchResult,
    messageApi,
    contextHolder,
  };
};

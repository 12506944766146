import { DatabaseOutlined } from "@ant-design/icons";
import { useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form, Modal, TreeSelect } from "antd";
import React from "react";
import { abTestingCubeOptions } from "../../../mock/ab-testing-cube-options";
import { mappingTreeData } from "./filter-modal";
const treeDataMapping = mappingTreeData(abTestingCubeOptions);

type BreakdownModalProps = {
  targetKey: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
export const MetricBreakDown: React.FC<BreakdownModalProps> = ({ targetKey, open, setOpen }) => {
  const { form, handleUpdateMetricTabItems } = useMetricPerformanceContext();
  const [value, setValue] = React.useState<string | undefined>(
    form.getFieldValue([targetKey, ChartFormFieldsEnum.METRIC_BREAKDOWN]),
  );

  const metricBreakdownWatch = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_BREAKDOWN], form);

  const handleOk = () => {
    form.setFieldValue([targetKey, ChartFormFieldsEnum.METRIC_BREAKDOWN], value);
    handleUpdateMetricTabItems(targetKey, ChartFormFieldsEnum.METRIC_BREAKDOWN);
    setOpen(false);
  };
  return (
    <div className="flex justify-center">
      <div
        className="flex items-center cursor-pointer hover:text-blue-600 text-gray-500 "
        onClick={() => {
          setOpen(true);
        }}
      >
        <DatabaseOutlined className="mr-2" />
        Breakdown ({metricBreakdownWatch?.length ?? 0})
      </div>
      <Modal open={open} onCancel={() => setOpen(false)} onOk={handleOk} width={550} title="Metric breakdown" centered>
        <TreeSelect
          allowClear
          value={value}
          placeholder="Metric Breakdown"
          showSearch
          multiple
          onChange={(value: string) => {
            setValue(value);
          }}
          treeData={treeDataMapping}
          style={{ width: 500 }}
          treeLine={{
            showLeafIcon: false,
          }}
        />
      </Modal>
      <Form.Item name={[targetKey, ChartFormFieldsEnum.METRIC_BREAKDOWN]} hidden />
    </div>
  );
};

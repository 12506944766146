import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form } from "antd";
import { FormInstance } from "antd/lib/form";
import React from "react";
import { useGetOptionExtraField } from "@src/hooks/ab-testing/use-get-option-extra-field";
import { BaseSelectExtraField } from "./base-select-extra-field";

export const LastAppVersion: React.FC<{ form: FormInstance }> = ({ form }) => {
  const { isLoading, options } = useGetOptionExtraField({
    dimension: "last_app_version",
    fieldName: FilterFormFieldsEnum.EXTRA_LAST_APP_VERSION,
    form,
  });

  return (
    <Form.Item
      className="w-[50%] max-w-[600px]"
      labelCol={{
        span: 6,
      }}
      name={FilterFormFieldsEnum.EXTRA_LAST_APP_VERSION}
      label="Last App Version"
    >
      <BaseSelectExtraField loading={isLoading} placeholder="First App Version" options={options} />
    </Form.Item>
  );
};

import { BaseSelectRef } from "rc-select/lib/BaseSelect";
import { css } from "@emotion/react";
import { Select, SelectProps, Spin, Tooltip } from "antd";

export const SelectWithSpinAndCustomMaxTag: React.FC<
  SelectProps<any, any> & {
    children?: React.ReactNode;
  } & {
    ref?: React.Ref<BaseSelectRef> | undefined;
  }
> = ({ ...props }) => {
  const renderMaxTagPlaceholder = (omits: any[]) => {
    const ele = (
      <ul
        css={css`
          &::-webkit-scrollbar-track {
            background-color: rgba(0, 0, 0, 0.8);
          }
        `}
        className="max-h-[200px] overflow-y-auto p-2"
      >
        {omits?.map((omit) => {
          return <li key={omit.value}>{omit.label}</li>;
        })}
      </ul>
    );

    return (
      <Tooltip title={ele}>
        <div>
          + {omits.length}
          {omits.length > 1 ? " ..." : ""}
        </div>
      </Tooltip>
    );
  };
  return (
    <Select
      maxTagCount={"responsive"}
      maxTagPlaceholder={renderMaxTagPlaceholder}
      dropdownRender={(ele) => {
        return <Spin spinning={props.loading ?? false}>{ele}</Spin>;
      }}
      {...props}
    />
  );
};

import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { PivotConfig, ResultSet } from "@cubejs-client/core";
import { Button, Select, Table, Tag } from "antd";
import { uniqueId } from "lodash";
import React from "react";

type IPropsTableResultQueryBuilder = {
  resultSet?: ResultSet<any>;
  pivotConfig: PivotConfig;
  paginationControl: {
    pageSize: number;
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    setPageSize: React.Dispatch<React.SetStateAction<number>>;
  };
  refetchResult: ({ offset, limit }: { offset: number; limit: number }) => Promise<ResultSet<any> | undefined>;
  isLoading: boolean;
};

export const TableResultQueryBuilder: React.FC<IPropsTableResultQueryBuilder> = ({
  resultSet,
  pivotConfig,
  paginationControl,
  refetchResult,
  isLoading,
}) => {
  const { page, pageSize, setPage, setPageSize } = paginationControl;

  const dataSource = (resultSet?.tablePivot(pivotConfig) || []).map((item: any) => {
    const _item = Object.entries(item).reduce((acc: any, item: any) => {
      const value = item[1] === null ? <Tag>null</Tag> : item[1];
      acc[item[0]] = value;
      return acc;
    }, {});
    return {
      ..._item,
      id: uniqueId(),
    };
  });

  const [typeButtonClicked, setTypeButtonClicked] = React.useState<"prev" | "next">("prev");

  const tableWrapperRef = React.useRef<HTMLDivElement>(null);
  const [tableWrapperHeight, setTableWrapperHeight] = React.useState<number>(0);

  React.useEffect(() => {
    const observer = new ResizeObserver(() => {
      setTableWrapperHeight(tableWrapperRef.current?.clientHeight || 0);
    });
    tableWrapperRef.current && observer.observe(tableWrapperRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const pageSizeOptions = [20, 50, 100, 200, 500, 1000, 2000].map((item) => ({
    label: `${item.toString()} / page`,
    value: item,
  }));
  const handleRefetchResult = (_page: number, _pageSize: number) => {
    setPage(_page);
    setPageSize(_pageSize);
    refetchResult({
      offset: (_page - 1) * _pageSize,
      limit: _pageSize,
    });
  };

  const ButtonWrapper = ({
    disabled,
    selecting,
    onClick,
    children,
  }: {
    disabled: boolean;
    selecting: boolean;
    onClick: () => void;
    children: React.ReactNode;
  }) => {
    const getColor = () => {
      if (disabled) {
        return "text-gray-300";
      }
      if (selecting) {
        return "text-blue-500";
      }
      return "text-black";
    };

    return (
      <Button
        type="text"
        disabled={disabled}
        onClick={onClick}
        className={`${getColor()} text-[18px] flex items-center`}
      >
        {children}
      </Button>
    );
  };
  return (
    <div className="h-full relative">
      <div ref={tableWrapperRef} className="h-[calc(100%-100px)] ">
        <Table
          virtual={true}
          loading={isLoading}
          rowKey={"id"}
          size="small"
          dataSource={dataSource}
          columns={resultSet?.tableColumns(pivotConfig)}
          scroll={{ y: tableWrapperHeight - 50 }}
          sticky={{ offsetHeader: 0 }}
          pagination={false}
        />
      </div>
      <div className="w-full flex justify-around  pt-2">
        <div className="flex items-center">
          <ButtonWrapper
            disabled={page < 2}
            selecting={typeButtonClicked === "prev"}
            onClick={() => {
              setTypeButtonClicked("prev");
              handleRefetchResult(page - 1, pageSize);
            }}
          >
            <LeftOutlined />
          </ButtonWrapper>
          <ButtonWrapper
            disabled={dataSource.length < pageSize}
            selecting={typeButtonClicked === "next"}
            onClick={() => {
              setTypeButtonClicked("next");
              handleRefetchResult(page + 1, pageSize);
            }}
          >
            <RightOutlined />
          </ButtonWrapper>

          <Select
            className="ml-2"
            defaultValue={pageSize}
            size="middle"
            showSearch
            style={{
              width: 120,
            }}
            options={pageSizeOptions}
            onChange={(value) => {
              handleRefetchResult(1, value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

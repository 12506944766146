import React from "react";
import { Button, Form, Input, Modal, TreeSelect } from "antd";
import "react-datepicker/dist/react-datepicker.css";
// types

// helpers
import { mapMemberToTreeNode } from "@src/helpers/tree-select-member";

// components
import MemberGroupBase from "./member-group-base";
import { QueryBuilderAntD } from "@react-querybuilder/antd";
import QueryBuilder, { FieldSelectorProps, RuleGroupType } from "react-querybuilder";
import "@src/styles/query-builder.scss";
import { deserialize, checkValidQuery, serialize } from "@src/helpers/react-querybuilder";
import { CustomValueEditor } from "@src/components/react-querybuilder/custom-value-editor";
import { config, queryBuilderOperators } from "@src/config";
import { OperatorType } from "@src/types";
import { Portal } from "@src/components/portal";
import { useModal } from "@refinedev/antd";
import { FullscreenOutlined } from "@ant-design/icons";
import { getModeOfLocation } from "@src/util/fb-exploration/get-mode-of-location";
import { QueryMemberGroupFilterExploreProps } from "@src/types/query-builder-firebase";
import { useExploreContext } from "@src/contexts/explore-context";

export const booleanOperators = ["set", "notSet"];

function MemberGroupFilterExplore({
  title,
  availableMembers,
  query,
  isFetchingMeta,
  updateQuery,
}: QueryMemberGroupFilterExploreProps) {
  const { manualFetchDryRun, form } = useExploreContext();
  const timeoutRef = React.useRef<NodeJS.Timeout>();

  const { isViewMode } = getModeOfLocation();
  const { modalProps, show, close } = useModal();

  const filtersWithoutAmaAppId = query?.filters?.filter((item: any) => {
    return item.member !== config.APP_REPORT_AMA_ID;
  });

  const filters = deserialize({ and: filtersWithoutAmaAppId });
  const onSetReactQuery = async (reactQuery: RuleGroupType) => {
    const isValidQuery = checkValidQuery(reactQuery.rules);
    const serializedQuery = serialize(reactQuery);

    const _filter = serializedQuery ? (serializedQuery?.and ? serializedQuery?.and : [serializedQuery]) : [];
    const _filterWithAmaAppId = [
      {
        member: config.APP_REPORT_AMA_ID,
        operator: "equals",
        values: form?.getFieldValue("platform") || [],
      },
      ..._filter,
    ];
    if (isValidQuery) {
      await manualFetchDryRun({
        query: {
          ...query,
          filters: _filterWithAmaAppId,
        },
        onSetters: () => {
          updateQuery({
            filters: _filterWithAmaAppId,
          });
        },
      });
    }
  };

  const cubOptions = React.useMemo(() => mapMemberToTreeNode(availableMembers), [availableMembers]);
  const getDefaultCheckedKeys = React.useMemo(
    () => (filtersWithoutAmaAppId ? filtersWithoutAmaAppId.map((m: any) => m.member) : []),
    [filtersWithoutAmaAppId],
  );

  const FieldSelector = (props: FieldSelectorProps) => {
    const { handleOnChange, rule } = props;
    const onChange = (newValue: string, opts: React.ReactNode[]) => {
      if (!opts[0]) return;
      handleOnChange(newValue);
    };

    return (
      <TreeSelect
        disabled={!!isViewMode}
        onChange={onChange}
        treeData={cubOptions}
        className="w-full"
        value={rule.field}
        showSearch
        placeholder="Select field..."
        treeNodeLabelProp={"label"}
        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
        treeLine
        filterTreeNode={(input, option) => {
          return (
            !option.children &&
            ((option?.key?.toString() ?? "").toLowerCase().includes(input.toLowerCase()) ||
              (option?.title?.toString() ?? "").toLowerCase().includes(input.toLowerCase()))
          );
        }}
      />
    );
  };

  const getInputType = (field: string, operator: string) => {
    const isRange = operator.toLowerCase().includes("range");
    const isBoolean = booleanOperators.includes(operator);
    const availableMember = availableMembers.find((member) => member.name === field);
    const type = availableMember?.type;
    const isSwitch = operator === "equals" && type === "boolean";

    if (isRange && type === "time") return "dateRange";
    if (isBoolean) return "empty";
    if (isSwitch) return "switch";
    return availableMember?.type || "string";
  };

  const getOperators = (field: string) => {
    const availableMember = availableMembers.find((member) => member.name === field);
    const type: OperatorType = (availableMember?.type as OperatorType) || "string";
    const options =
      queryBuilderOperators[type]?.map((item) => {
        return {
          label: item.title,
          value: item.name,
          name: item.name,
        };
      }) || [];
    return options;
  };

  return (
    <>
      <Modal {...modalProps} title="Filter" width={1000} onOk={close} centered>
        <div id="modal-filter"></div>
      </Modal>
      <MemberGroupBase
        title={title}
        dataSource={cubOptions}
        query={query}
        isFetchingMeta={isFetchingMeta}
        getDefaultCheckedKeys={getDefaultCheckedKeys}
        showAdd={false}
        onOk={() => {
          return;
        }}
      >
        <Button className="absolute top-1 right-1" type="text" onClick={show} icon={<FullscreenOutlined />} />

        <Portal containerId="modal-filter" disabled={!modalProps.open}>
          <QueryBuilderAntD>
            <QueryBuilder
              key={JSON.stringify(filters)}
              disabled={!!isViewMode}
              fields={cubOptions}
              controlClassnames={{ queryBuilder: `queryBuilder-branches ${!modalProps.open ? "explore-builder" : ""}` }}
              controlElements={{
                fieldSelector: FieldSelector,
                valueEditor: CustomValueEditor,
              }}
              defaultQuery={filters}
              onQueryChange={(query) => {
                timeoutRef.current && clearTimeout(timeoutRef.current);
                timeoutRef.current = setTimeout(() => {
                  onSetReactQuery(query);
                }, config.DEBOUNCE_TIME.MEDIUM_DELAY);
              }}
              getInputType={getInputType}
              getOperators={getOperators}
              resetOnOperatorChange
              autoSelectField={false}
              autoSelectOperator={false}
            />
          </QueryBuilderAntD>
        </Portal>
        <Form.Item hidden name="filterExplore">
          <Input />
        </Form.Item>
        <Form.Item hidden name="isValidQuery">
          <Input />
        </Form.Item>
      </MemberGroupBase>
    </>
  );
}

export default MemberGroupFilterExplore;

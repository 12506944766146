export const abTestingCubeOptions = [
  {
    title: "App Report",
    key: "app report",
    value: "app report",
    children: [
      {
        title: "Ama App Id",
        label: "Ama App Id",
        key: "app_report.ama_app_id",
        value: "app_report.ama_app_id",
        public: true,
        disabled: true,
      },
      {
        title: "Composite Key",
        label: "Composite Key",
        key: "app_report.composite_key",
        value: "app_report.composite_key",
        public: false,
        disabled: false,
      },
      {
        title: "Date Tzutc",
        label: "Date Tzutc",
        key: "app_report.date_tzutc",
        value: "app_report.date_tzutc",
        public: true,
        disabled: false,
      },
      {
        title: "Product Code",
        label: "Product Code",
        key: "app_report.product_code",
        value: "app_report.product_code",
        public: true,
        disabled: false,
      },
    ],
  },
  {
    title: "User Ab Testing",
    key: "user ab testing",
    value: "user ab testing",
    children: [
      {
        title: "Abalyzer v2 ID",
        label: "Abalyzer v2 ID",
        key: "user_ab_testing.aba_id",
        value: "user_ab_testing.aba_id",
        public: true,
        disabled: false,
      },
      {
        title: "Ama App Id",
        label: "Ama App Id",
        key: "user_ab_testing.ama_app_id",
        value: "user_ab_testing.ama_app_id",
        public: false,
        disabled: false,
      },
      {
        title: "Composite Key",
        label: "Composite Key",
        key: "user_ab_testing.composite_key",
        value: "user_ab_testing.composite_key",
        public: false,
        disabled: false,
      },
      {
        title: "Country Code",
        label: "Country Code",
        key: "user_ab_testing.country_code",
        value: "user_ab_testing.country_code",
        public: false,
        disabled: false,
      },
      {
        title: "Device Category",
        label: "Device Category",
        key: "user_ab_testing.device_category",
        value: "user_ab_testing.device_category",
        public: false,
        disabled: false,
      },
      {
        title: "Device Model",
        label: "Device Model",
        key: "user_ab_testing.device_model",
        value: "user_ab_testing.device_model",
        public: false,
        disabled: false,
      },
      {
        title: "Exp Composite Id",
        label: "Exp Composite Id",
        key: "user_ab_testing.exp_composite_id",
        value: "user_ab_testing.exp_composite_id",
        public: true,
        disabled: false,
      },
      {
        title: "Experiment Group",
        label: "Experiment Group",
        key: "user_ab_testing.exp_group",
        value: "user_ab_testing.exp_group",
        public: true,
        disabled: false,
      },
      {
        title: "Experiment ID",
        label: "Experiment ID",
        key: "user_ab_testing.exp_id",
        value: "user_ab_testing.exp_id",
        public: true,
        disabled: false,
      },
      {
        title: "First App Version",
        label: "First App Version",
        key: "user_ab_testing.first_app_version",
        value: "user_ab_testing.first_app_version",
        public: false,
        disabled: false,
      },
      {
        title: "First Signal Date",
        label: "First Signal Date",
        key: "user_ab_testing.first_signal_date",
        value: "user_ab_testing.first_signal_date",
        public: false,
        disabled: false,
      },
      {
        title: "Has Multi User Pseudo Id",
        label: "Has Multi User Pseudo Id",
        key: "user_ab_testing.has_multi_user_pseudo_id",
        value: "user_ab_testing.has_multi_user_pseudo_id",
        public: false,
        disabled: false,
      },
      {
        title: "In Multi Exp Group",
        label: "In Multi Exp Group",
        key: "user_ab_testing.in_multi_exp_group",
        value: "user_ab_testing.in_multi_exp_group",
        public: false,
        disabled: false,
      },
      {
        title: "Install Date Tzutc",
        label: "Install Date Tzutc",
        key: "user_ab_testing.install_date_tzutc",
        value: "user_ab_testing.install_date_tzutc",
        public: false,
        disabled: false,
      },
      {
        title: "Is Late Signal",
        label: "Is Late Signal",
        key: "user_ab_testing.is_late_signal",
        value: "user_ab_testing.is_late_signal",
        public: false,
        disabled: false,
      },
      {
        title: "Is Valid Country",
        label: "Is Valid Country",
        key: "user_ab_testing.is_valid_country",
        value: "user_ab_testing.is_valid_country",
        public: false,
        disabled: false,
      },
      {
        title: "Last App Version",
        label: "Last App Version",
        key: "user_ab_testing.last_app_version",
        value: "user_ab_testing.last_app_version",
        public: false,
        disabled: false,
      },
      {
        title: "Master Device Id",
        label: "Master Device Id",
        key: "user_ab_testing.master_device_id",
        value: "user_ab_testing.master_device_id",
        public: false,
        disabled: false,
      },
      {
        title: "Media Source",
        label: "Media Source",
        key: "user_ab_testing.media_source",
        value: "user_ab_testing.media_source",
        public: false,
        disabled: false,
      },
      {
        title: "Second Group Date",
        label: "Second Group Date",
        key: "user_ab_testing.second_group_date",
        value: "user_ab_testing.second_group_date",
        public: false,
        disabled: false,
      },
      {
        title: "User Pseudo Id",
        label: "User Pseudo Id",
        key: "user_ab_testing.user_pseudo_id",
        value: "user_ab_testing.user_pseudo_id",
        public: false,
        disabled: false,
      },
      {
        title: "Distinct User",
        label: "Distinct User",
        key: "user_ab_testing.distinct_user",
        value: "user_ab_testing.distinct_user",
        public: true,
        disabled: false,
      },
      {
        title: "Max First Signal Date",
        label: "Max First Signal Date",
        key: "user_ab_testing.max_first_signal_date",
        value: "user_ab_testing.max_first_signal_date",
        public: true,
        disabled: false,
      },
      {
        title: "Min First Signal Date",
        label: "Min First Signal Date",
        key: "user_ab_testing.min_first_signal_date",
        value: "user_ab_testing.min_first_signal_date",
        public: true,
        disabled: false,
      },
    ],
  },
];

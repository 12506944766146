import React from "react";

export const useMyTimeout = () => {
  const timeoutIdRef = React.useRef<NodeJS.Timeout>();

  const mySetTimeout = (cb: (...args: any[]) => void, ms: number) => {
    timeoutIdRef.current && clearTimeout(timeoutIdRef.current);
    timeoutIdRef.current = setTimeout(() => {
      cb();
    }, ms);
  };
  return { mySetTimeout };
};

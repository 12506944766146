import { AntDValueEditor } from "@react-querybuilder/antd";
import { getModeOfLocation } from "@src/util/fb-exploration/get-mode-of-location";
import { DatePicker, Select, Switch, Tooltip } from "antd";
import dayjs from "dayjs";
import React from "react";
import { ValueEditorProps } from "react-querybuilder";
const dateFormat = "YYYY-MM-DD";

export const CustomValueEditor = (props: ValueEditorProps) => {
  const { isViewMode } = getModeOfLocation();

  const type = props.inputType || "string";
  React.useEffect(() => {
    type === "switch" && props.handleOnChange(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  if (["date", "time"].includes(type)) {
    const defaultValue = Array.isArray(props.value) ? props.value[0] : props.value;
    return (
      <div className="rule-value">
        <DatePicker
          disabled={!!isViewMode}
          format={dateFormat}
          defaultValue={defaultValue ? dayjs(defaultValue) : null}
          onChange={(d) => props.handleOnChange(d ? [dayjs(d).format(dateFormat)] : null)}
        />
      </div>
    );
  } else if (type === "dateRange") {
    const [_a, _b] = props.value ?? [null, null];
    return (
      <div className="rule-value">
        <DatePicker.RangePicker
          disabled={!!isViewMode}
          format={dateFormat}
          defaultValue={props.value ? [dayjs(_a), dayjs(_b)] : undefined}
          onChange={(update: any) => {
            if (!update) {
              props.handleOnChange(update);
              return;
            }
            const [a, b] = update;
            props.handleOnChange([dayjs(a).format(dateFormat), dayjs(b).format(dateFormat)]);
          }}
        />
      </div>
    );
  } else if (type === "switch") {
    return (
      <div>
        <Switch
          disabled={!!isViewMode}
          defaultChecked={props.value}
          checkedChildren={"true"}
          unCheckedChildren={"false"}
          onChange={(e) => {
            props.handleOnChange(e);
          }}
        />
      </div>
    );
  } else if (type === "string") {
    return (
      <div className="rule-value">
        <Select
          disabled={!!isViewMode}
          className="w-full"
          mode="tags"
          defaultValue={props.value || []}
          onChange={(value) => {
            props.handleOnChange(value);
          }}
          placeholder={"Enter value"}
          maxTagCount={3}
          maxTagPlaceholder={(values) => {
            return (
              <Tooltip
                title={
                  <div className="flex flex-col">
                    {values.map((v) => {
                      return <span key={v.value}>{v.label}</span>;
                    })}
                  </div>
                }
              >
                + {values.length} ...
              </Tooltip>
            );
          }}
        />
      </div>
    );
  } else if (type === "empty") {
    return <></>;
  }
  return <AntDValueEditor {...props} disabled={!!isViewMode} />;
};

import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Checkbox, Form, Select } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { InputSkeletonLayout } from "../../layout/input-skeleton-layout";
import { useLoadAbExploration } from "@src/hooks/ab-testing/use-ab-exploration-get-result";
import { SupportedModelAlias } from "@src/constant/ab-testing/ad-testing-exploration.enum";

export const AbaIdFilter: React.FC<{ form: FormInstance }> = ({ form }) => {
  const productCodeWatch = Form.useWatch(FilterFormFieldsEnum.PRODUCT_CODE, form);
  const isReady = productCodeWatch;
  const { result, isLoading } = useLoadAbExploration({
    productCode: productCodeWatch,
    modelAlias: SupportedModelAlias.USER_INFO,
    query: {
      dimensions: ["aba_id"],
      limit: 10000,
      order: [["aba_id", "desc"]],
    },
    options: {
      // TODO: get form value instead
      cohort_date: "first_signal_date",
    },
    isSkip: !isReady,
  });

  const options = isLoading
    ? []
    : result?.map((item: any) => {
        return {
          label: item["aba_id"],
          value: item["aba_id"],
        };
      });

  return (
    <InputSkeletonLayout
      width={200}
      form={form}
      isLoading={isLoading}
      label="Aba Id"
      required={true}
      placeholder="Select Aba Id"
    >
      <Form.Item label="Aba Id" name={FilterFormFieldsEnum.ABA_ID} required>
        <Select
          disabled={!productCodeWatch}
          allowClear
          placeholder="Select Aba Id"
          options={options}
          showSearch
          filterOption={(input, option) => {
            const { label, value } = option as any;
            if (!value || !label) return false;
            return (
              label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              value.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
          onChange={() => {
            form.setFieldsValue({ [FilterFormFieldsEnum.IS_ABA_ID_CHANGED]: true });
          }}
          style={{
            width: 200,
          }}
        />
      </Form.Item>
      <Form.Item name={FilterFormFieldsEnum.IS_ABA_ID_CHANGED} valuePropName="checked" hidden>
        <Checkbox />
      </Form.Item>
    </InputSkeletonLayout>
  );
};

import { SmileOutlined } from "@ant-design/icons";

export const ApplyMetric: React.FC = () => {
  return (
    <div className="flex justify-center">
      <span className="text-blue-500 cursor-pointer">
        <SmileOutlined /> Apply
      </span>
    </div>
  );
};

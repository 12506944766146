import { Edit, ListButton } from "@refinedev/antd";
import { useExploreContext } from "@src/contexts/explore-context";
import { Card, Checkbox, Form, Spin } from "antd";
import React from "react";
import ExploreQueryNew from "@src/pages/dashboard/components/explore-builder-new";
import { useNavigation, useParsed, useUpdate } from "@refinedev/core";
import { useGetExplorations } from "@src/hooks/use-get-explorations";
import { GeneralInfoWrapper } from "./form/general-info-wrapper";
import { ChartResultProvider } from "./form/chart-result-provider";
import { QueryBuilderChartLayout } from "@src/layouts/firebase-explore/query-builder-chart-layout";

export const FirebaseExploreEdit: React.FC = () => {
  const { push } = useNavigation();
  const [_form] = Form.useForm();
  const { form: formContext } = useExploreContext();
  const form = formContext ?? _form;

  const { id } = useParsed();
  const { exploration, isLoading } = useGetExplorations(id as string);

  const { query, chartType, pivotConfig } = useExploreContext();

  const { mutateAsync: updateFirebaseExplore, isLoading: isLoadingSave } = useUpdate();

  const formLoading = Form.useWatch("form_loading", form);

  const handleFinish = async (e: any) => {
    form.validateFields();

    const payload = {
      title: e.name,
      description: e?.description,
      product_code: e.product_code,
      is_public: true,
      chart_type: chartType,
      pivot_config: pivotConfig,
      filter: query?.filters,
      dimensions: query?.dimensions ?? [],
      measures: query?.measures ?? [],
      segments: query?.segments ?? [],
      order: query?.order,
      time_dimensions: query?.timeDimensions,
      limit: 100,
    };

    updateFirebaseExplore({
      id: id as string,
      resource: `exploration/fb-exploration`,
      values: payload,
      meta: {
        method: "put",
      },
      successNotification: () => {
        return {
          type: "success",
          message: "Exploration updated successfully",
        };
      },
      errorNotification: () => {
        return {
          type: "error",
          message: "Exploration updated failed",
        };
      },
    });
  };

  if (isLoading) return <Spin />;
  return (
    <Edit
      isLoading={isLoading || isLoadingSave || !!formLoading}
      title="Edit exploration"
      headerButtons={<ListButton onClick={() => push("/")}>Explorations</ListButton>}
      contentProps={{
        className: "bg-transparent shadow-none card-custom",
      }}
      footerButtonProps={{
        hidden: true,
      }}
      wrapperProps={{
        className: "base-custom-container",
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          form_loading: false,
          name: exploration?.name,
          product_code: exploration?.productCode,
          description: exploration?.description,
          platform: exploration?.platform,
          "time-range": exploration?.["time-range"],
          "custom-time-range": exploration?.["custom-time-range"],
        }}
        onFinish={handleFinish}
      >
        <Card size="small" title={"General Information"} className=" mb-2 ">
          <div className="flex mb-4">
            <div className="w-3/4">
              <GeneralInfoWrapper form={form} mode="edit" />
            </div>
            <div id="actions-wrapper" className="self-end w-1/4" />
          </div>
        </Card>

        <QueryBuilderChartLayout
          autoSaveId="explore-query-builder-edit"
          queryBuilderComponent={<ExploreQueryNew />}
          showResultComponent={<ChartResultProvider form={form} mode="edit" exploration={exploration} />}
        />
        <Form.Item name="form_loading" hidden valuePropName="checked">
          <Checkbox />
        </Form.Item>
      </Form>
    </Edit>
  );
};

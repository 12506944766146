import { SelectWithSpinAndCustomMaxTag } from "@src/components/select/SelectWithCustomMaxTag";
import { useABContext } from "@src/contexts/ab-testing/ab-context";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { getValueOfFiltersForm } from "@src/pages/ab-test-explore/helper/get-value-of-filters-form";
import { mappingLabelVariant } from "@src/pages/ab-test-explore/helper/mapping-label-variant";
import { Form } from "antd";
import { FormInstance } from "antd/lib/form";
import React from "react";

export const BaseVariant: React.FC<{ form: FormInstance<any> }> = ({ form }) => {
  const isFirstRender = React.useRef(true);
  const { updateSearchUrl } = useABContext();

  const filterField = Form.useWatch(ChartFormFieldsEnum.FILTER_FIELDS, form);
  const { variants } = getValueOfFiltersForm(filterField);

  const options = React.useMemo(() => {
    return (variants ?? [])?.map((item) => {
      return {
        label: mappingLabelVariant(item),
        value: item,
      };
    });
  }, [JSON.stringify(variants)]);

  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const currentValue = form.getFieldValue(ChartFormFieldsEnum.METRIC_BASE_VARIANTS);
    if (!currentValue) return;
    if (!options?.some((option) => option.value === currentValue)) {
      form.setFieldValue(ChartFormFieldsEnum.METRIC_BASE_VARIANTS, undefined);
    }
  }, [options]);

  return (
    <Form.Item
      label="Base variant"
      name={ChartFormFieldsEnum.METRIC_BASE_VARIANTS}
      required
      rules={[
        {
          message: "Please select base variant",
          required: true,
        },
      ]}
    >
      <SelectWithSpinAndCustomMaxTag
        options={options}
        style={{ width: 300 }}
        placeholder="Base variants"
        onChange={() => {
          updateSearchUrl();
        }}
      />
    </Form.Item>
  );
};

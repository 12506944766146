import React from "react";
import { ChartActionProps } from ".";
import { Button, Modal } from "antd";
import { useChartWrapperContext } from "@src/contexts/ab-testing/chart-wrapper-context";

import QueryViewer from "@src/components/query-builder/query-viewer-v2";
import { ConsoleSqlOutlined } from "@ant-design/icons";

export const RenderedQuery: React.FC<ChartActionProps> = ({ tabKey }) => {
  const { renderedQueries } = useChartWrapperContext();
  const [open, setOpen] = React.useState(false);
  const query = renderedQueries[tabKey];

  return (
    <div className="">
      <Button
        type="text"
        disabled={!query}
        onClick={() => setOpen(true)}
        className="w-full flex justify-start items-center"
      >
        <ConsoleSqlOutlined className="pb-1 text-[16px]" />
        View Rendered SQL Query
      </Button>
      <Modal
          title="Rendered Query"
          centered
          open={open}
          onCancel={() => setOpen(false)}
          onOk={() => {
            setOpen(true);
          }}
          width={800}
        >
          <div className="h-[600px] overflow-y-scroll">
            <QueryViewer query={query}/>
          </div>
        </Modal>
    </div>
  );
};

import { css } from "@emotion/react";

interface TreeSelectLayoutProps {
  children?: React.ReactNode;
}
export const TreeSelectLayout: React.FC<TreeSelectLayoutProps> = ({ children }) => {
  return (
    <div
      className="max-h-[400px] overflow-auto"
      css={css`
        .ant-tree-list-scrollbar-vertical {
          width: 4px !important;
          & > div {
            background-color: #0000005e !important;
          }
        }
      `}
    >
      {children}
    </div>
  );
};

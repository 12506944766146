export enum SupportedModelAlias {
  USER_INFO = "user-info",
  ENGAGEMENT = "engagement",
  AD_MONETIZATION = "ad-monetization",
}

export enum FilterOperator {
  EQUALS = "equals",
  BETWEEN = "between",
}


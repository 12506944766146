import { FormInstance } from "antd/lib/form";
import { FilterOutUser } from "./filter-out-user";
import {
  Country,
  DeviceCategory,
  DeviceModal,
  ExtraInstallDate,
  ExtraOnboardingDate,
  FirstAppVersion,
  LastAppVersion,
  MediaSource,
} from "./form";
import { css } from "@emotion/react";

const FormGroupLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <div className="flex justify-around  w-full">{children}</div>;
};
export const ExtraFieldModalContent: React.FC<{ form: FormInstance }> = ({ form }) => {
  return (
    <div
      css={css`
        .ant-picker-range {
          background-color: white;
        }
      `}
    >
      <FilterOutUser form={form} />
      <FormGroupLayout>
        <ExtraInstallDate form={form} />
        <ExtraOnboardingDate form={form} />
      </FormGroupLayout>
      <FormGroupLayout>
        <Country form={form} />
        <MediaSource form={form} />
      </FormGroupLayout>
      <FormGroupLayout>
        <DeviceModal form={form} />
        <DeviceCategory form={form} />
      </FormGroupLayout>
      <FormGroupLayout>
        <FirstAppVersion form={form} />
        <LastAppVersion form={form} />
      </FormGroupLayout>
    </div>
  );
};

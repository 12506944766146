import { css } from "@emotion/react";
import { Spin } from "antd";
export const ChartLayout: React.FC<{ children: any; loading?: boolean }> = ({ children, loading = false }) => {
  return (
    <Spin spinning={loading}>
      <div
        css={css`
          .ant-tabs-nav-wrap {
            justify-content: end;
          }
        `}
        // className="h-[700px]"
      >
        {children}
      </div>
    </Spin>
  );
};

import { ALL_EXTRA_FIELDS_FILTER, ALL_EXTRA_FIELDS_WITH_TYPE_BOOLEAN } from "../contanst/filter-fields";
import { CHIP_LABELS, CHIP_RELATIONS } from "../contanst/chips";

type ResultGroupingExtraField = { label: string; name: string; relation: string; value: string[] };
export const FILTER_OUT_USER_NAME = "filterOutUser";

export const getGroupingExtraField = (extraFilterValues: Record<string, any>) => {
  const extraFieldsValues: Record<string, any> = {};

  const getFilterOutUserResult = {
    label: "Filter out user result",
    relation: "with",
    name: FILTER_OUT_USER_NAME,
    value: [] as string[],
  };
  const getOtherResult: ResultGroupingExtraField[] = [];

  ALL_EXTRA_FIELDS_FILTER.forEach((field) => {
    if (extraFilterValues[field]) {
      if (ALL_EXTRA_FIELDS_WITH_TYPE_BOOLEAN.includes(field)) {
        getFilterOutUserResult.value.push(CHIP_LABELS[field]);
      } else {
        extraFieldsValues[field] = extraFilterValues[field];
        getOtherResult.push({
          label: CHIP_LABELS[field],
          relation: CHIP_RELATIONS[field],
          value: extraFilterValues[field],
          name: field,
        });
      }
    }
  });

  return getFilterOutUserResult.value.length > 0 ? [getFilterOutUserResult, ...getOtherResult] : getOtherResult;
};

import { FormInstance } from "antd/lib/form";
import { useGetExtraValueField } from "./use-get-extra-value-field";
import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { getRegionName } from "@src/util/get-region-name";
import React from "react";

type UseGetExtraValueFieldProps = {
  form: FormInstance;
  dimension: string;
  fieldName: string;
};
export const useGetOptionExtraField = ({ form, dimension, fieldName }: UseGetExtraValueFieldProps) => {
  const { isLoading, getOptions, removeRedundantData } = useGetExtraValueField({ form, dimension: [dimension] });

  const optionCallback =
    fieldName === FilterFormFieldsEnum.EXTRA_COUNTRY
      ? {
          labelMapping: (item: string) => {
            const regionName = getRegionName(item);
            return regionName === item ? item : `${regionName} (${item})`;
          },
        }
      : undefined;

  const options = getOptions(dimension, optionCallback);

  React.useEffect(() => {
    if (isLoading) return;
    removeRedundantData({ field_name: fieldName, newOptions: options });
  }, [options, isLoading, removeRedundantData, fieldName]);

  return { isLoading, options };
};

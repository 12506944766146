import React from "react";
import { InputNumber } from "antd";

import { Query } from "@cubejs-client/core";

type IProps = {
  limit: number;
  updateQuery: any;
  query: Query;
  disabled: boolean;
};

function LimitDropdown({ limit: initialLimit, updateQuery, query, disabled }: IProps) {
  const [limit, setLimit] = React.useState<any>(100);

  React.useEffect(() => {
    setLimit(initialLimit);
  }, [initialLimit]);

  return (
    <InputNumber
      type="number"
      value={limit}
      step={1}
      min={0}
      disabled={disabled}
      onChange={(value) => {
        updateQuery({ ...query, limit: value });
        setLimit(value);
      }}
    />
  );
}

export default LimitDropdown;

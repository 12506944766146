import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

type TooltipLayoutProps = {
  label: string;
  innerTooltipWidth?: number;
  content: string | React.ReactNode;
};
export const TooltipChartLayout: React.FC<TooltipLayoutProps> = ({ label, innerTooltipWidth = 500, content }) => {
  return (
    <div>
      {label}
      <Tooltip
        overlayInnerStyle={{ width: innerTooltipWidth, padding: 0 }}
        overlay={
          <div onClick={(e) => e.stopPropagation()} className="p-2">
            {content}
          </div>
        }
      >
        <ExclamationCircleOutlined className="ml-2" />
      </Tooltip>
    </div>
  );
};

import { useChartWrapperContext } from "@src/contexts/ab-testing/chart-wrapper-context";
import { Button, message } from "antd";
import { CSVLink } from "react-csv";
import { ChartActionProps } from ".";
import { DownloadOutlined } from "@ant-design/icons";

export const ExportCSV: React.FC<ChartActionProps> = ({ tabKey, tabName }) => {
  const { dataSources, columns } = useChartWrapperContext();
  const [messageApi, contextHolder] = message.useMessage();

  const data = dataSources[tabKey] || [];
  const header =
    columns[tabKey]?.map((c: any) => {
      return {
        label: c.title,
        key: c.dataIndex,
      };
    }) ?? [];

  return (
    <div>
      {contextHolder}
      <CSVLink filename={tabName} data={data} headers={header} className="text-black">
        <Button
          onClick={() => {
            messageApi.open({
              type: "success",
              content: "Successfully exported to CSV!",
            });
          }}
          type="text"
          disabled={!dataSources[tabKey]}
          className="w-full flex justify-start items-center"
        >
          <DownloadOutlined className="pb-1 text-[16px]" />
          Export CSV
        </Button>
      </CSVLink>
    </div>
  );
};

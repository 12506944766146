import { config } from "@src/config";
import { DATE_RANGE_CUSTOM_VALUE } from "@src/config/query-builder";
import dayjs from "dayjs";

export const isValidTimeRange = (timeRange: string | undefined, customTimeRange?: any) => {
  if (timeRange !== DATE_RANGE_CUSTOM_VALUE) return true;

  return customTimeRange?.length === 2 && customTimeRange?.every((v: any) => !!v);
};

export const convertDateRangeToString = (timeRange: string[] | undefined) => {
  if (!timeRange) {
    return undefined;
  }
  return timeRange.map((v) => dayjs(v).format(config.DATE_FORMAT));
};

export const convertDateRangeToDayJS = (timeRange: string[] | undefined) => {
  if (!timeRange) {
    return undefined;
  }
  return timeRange.map((v) => dayjs(v));
};

export const getDateRangeWithTime = (timeRange: string | undefined, customTimeRange?: any) => {
  if (!isValidTimeRange(timeRange, customTimeRange)) {
    return undefined;
  }
  if (timeRange !== DATE_RANGE_CUSTOM_VALUE) return timeRange;

  const [startDate, endDate] = convertDateRangeToString(customTimeRange) ?? [null, null];

  return timeRange === DATE_RANGE_CUSTOM_VALUE ? [startDate, endDate] : timeRange;
};

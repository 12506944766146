// Syntax highligh
import React from "react";
import { CopyOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import { format } from "sql-formatter";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useExploreContext } from "@src/contexts/explore-context";
import cube from "@cubejs-client/core";
import { config } from "@src/config";

type IProps = {
  query: any;
  cubeTokenProps?: any;
};
export default function QueryViewer({ query }: IProps) {
  const [messageApi, contextHolder] = message.useMessage();
  const formattedSql = format(query, {
    language: "bigquery",
    tabWidth: 2,
    keywordCase: "upper",
    linesBetweenQueries: 2,
  });

  function copySql() {
    navigator.clipboard.writeText(formattedSql);
    messageApi.open({
      type: "success",
      content: "Copy success!",
    });
  }

  return (
    <>
      {contextHolder}
      <div className="relative">
        <Button
          size="small"
          type="text"
          icon={<CopyOutlined />}
          className="absolute right-0 top-0"
          disabled={formattedSql === ""}
          style={{ color: formattedSql === "" ? "rgba(0, 0, 0, 0.25)" : "#096DD9" }}
          onClick={copySql}
        >
          Copy
        </Button>
        <SyntaxHighlighter language="sql" style={docco}>
          {formattedSql}
        </SyntaxHighlighter>
      </div>
    </>
  );
}

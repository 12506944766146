import React from "react";
import { ChartActionProps } from ".";
import { Button, Form, Modal } from "antd";
import { useChartWrapperContext } from "@src/contexts/ab-testing/chart-wrapper-context";

import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { getValueOfFiltersForm } from "@src/pages/ab-test-explore/helper/get-value-of-filters-form";
import QueryViewer from "@src/components/query-builder/query-viewer";
import { ConsoleSqlOutlined } from "@ant-design/icons";

export const SQLQuery: React.FC<ChartActionProps> = ({ tabKey, form }) => {
  const filtersFieldWatch = Form.useWatch(ChartFormFieldsEnum.FILTER_FIELDS, form);
  const { productToken } = getValueOfFiltersForm(filtersFieldWatch);

  const { queries } = useChartWrapperContext();
  const [open, setOpen] = React.useState(false);
  const query = queries[tabKey];

  const isShowModal = !!productToken && !!query;
  return (
    <div className="">
      <Button
        type="text"
        disabled={!query}
        onClick={() => setOpen(true)}
        className="w-full flex justify-start items-center"
      >
        <ConsoleSqlOutlined className="pb-1 text-[16px]" />
        View SQL Query
      </Button>
      {isShowModal && (
        <Modal
          title="SQL Query"
          centered
          open={open}
          onCancel={() => setOpen(false)}
          onOk={() => {
            setOpen(true);
          }}
          width={800}
        >
          <div className="h-[600px] overflow-y-scroll">
            <QueryViewer query={query} cubeTokenProps={productToken} />
          </div>
        </Modal>
      )}
    </div>
  );
};

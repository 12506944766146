import { FilterFormFieldsEnum } from "../enum";

export const ALL_EXTRA_FIELDS_WITH_TYPE_BOOLEAN = [
  FilterFormFieldsEnum.INVALID_COUNTRIES,
  FilterFormFieldsEnum.USERS_JOIN_MULTIPLE_GROUPS,
  FilterFormFieldsEnum.MULTI_USER_PSEUDO_ID,
  FilterFormFieldsEnum.LATE_ONBOARDING_DATE,
];

export const ALL_EXTRA_FIELDS_AS_DATE = [
  FilterFormFieldsEnum.EXTRA_INSTALL_DATE,
  FilterFormFieldsEnum.EXTRA_ONBOARDING_DATE,
];
export const ALL_EXTRA_FIELDS_AS_SELECT = [
  FilterFormFieldsEnum.EXTRA_COUNTRY,
  FilterFormFieldsEnum.EXTRA_MEDIA_SOURCE,
  FilterFormFieldsEnum.EXTRA_DEVICE_MODAL,
  FilterFormFieldsEnum.EXTRA_DEVICE_CATEGORY,
  FilterFormFieldsEnum.EXTRA_FIRST_APP_VERSION,
  FilterFormFieldsEnum.EXTRA_LAST_APP_VERSION,
];
export const ALL_EXTRA_FIELDS_WITH_TYPE_STRING_ARRAY = [...ALL_EXTRA_FIELDS_AS_DATE, ...ALL_EXTRA_FIELDS_AS_SELECT];

export const ALL_EXTRA_FIELDS_FILTER = [
  ...ALL_EXTRA_FIELDS_WITH_TYPE_BOOLEAN,
  ...ALL_EXTRA_FIELDS_AS_DATE,
  ...ALL_EXTRA_FIELDS_AS_SELECT,
];

import { Button, Dropdown } from "antd";
import { BaseButtonProps } from "antd/es/button/button";

type ButtonDropdownProps = {
  disabled?: boolean;
  overlay: React.ReactNode;
  children: string | React.ReactNode;
} & Omit<BaseButtonProps, "children">;
const ButtonDropdown = ({ disabled = false, overlay, ...buttonProps }: ButtonDropdownProps) => (
  <Dropdown
    dropdownRender={() => overlay}
    placement="bottomLeft"
    trigger={["click"]}
    disabled={disabled}
    destroyPopupOnHide
  >
    <Button {...buttonProps} />
  </Dropdown>
);

export default ButtonDropdown;

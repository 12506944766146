import React from "react";
import Search from "antd/lib/input/Search";

// hooks
import { useTreeSelect } from "@src/hooks/use-tree-select";

// helpers
import { filteredSelectedMembers } from "@src/helpers/tree-select-member";

// components
import TreeSelectBase from "./tree-select-base";
import { TreeDataNode } from "antd";
import { TreeSelectLayout } from "@src/components/tree-select/tree-select-layout";

type IProps = {
  dataSource: any;
  checkedKeys: React.Key[];
  defaultExpandAll?: boolean;
  isSelectedMode?: boolean;
  setCheckedKeys?: React.Dispatch<React.SetStateAction<React.Key[]>>;
  setSelectedMembers?: React.Dispatch<React.SetStateAction<TreeDataNode[]>>;
};

function TreeSelected({
  dataSource,
  checkedKeys,
  defaultExpandAll,
  isSelectedMode,
  setCheckedKeys,
  setSelectedMembers,
}: IProps) {
  const membersFiltered = React.useMemo(
    () => filteredSelectedMembers(dataSource, checkedKeys),
    [dataSource, checkedKeys],
  );

  const { onExpand, debouncedValue, onChange } = useTreeSelect({
    dataSource,
  });

  return (
    <>
      <Search
        disabled={membersFiltered.length === 0}
        placeholder="Search members"
        className="mb-4 hidden"
        onChange={onChange}
      />

      {membersFiltered.length > 0 ? (
        <TreeSelectLayout>
          <TreeSelectBase
            onExpand={onExpand}
            dataSource={membersFiltered}
            searchValue={debouncedValue}
            defaultExpandAll={defaultExpandAll}
            isSelectedMode={isSelectedMode}
            setCheckedKeys={setCheckedKeys}
            setSelectedMembers={setSelectedMembers}
          />
        </TreeSelectLayout>
      ) : (
        <div>No item!</div>
      )}
    </>
  );
}

export default TreeSelected;

import { USER_COUNT_CONSTANT } from "@src/constant/ab-testing/user-count";
import { TabsViewParamsEnum } from "@src/pages/ab-test-explore/enum";
import { getParamsFieldValue } from "../params";
import { ValueOfFilterFormType } from "@src/pages/ab-test-explore/helper/get-value-of-filters-form";

const OPERATOR_FILTER = {
  EQUALS: "equals",
};
export const mappingQueryUserCount = (
  generalFieldValues: ValueOfFilterFormType,
  chartKey: string,
  typeTabs?: TabsViewParamsEnum,
) => {
  const { abaId, variants, installDate, productToken, amaAppIds } = generalFieldValues;
  const filters: any = [
    abaId
      ? {
          member: USER_COUNT_CONSTANT.DIMENSIONS.USER_AB_TESTING_ABA_ID,
          operator: OPERATOR_FILTER.EQUALS,
          values: [abaId?.toString()],
        }
      : null,
    variants && variants?.length > 0
      ? {
          member: USER_COUNT_CONSTANT.DIMENSIONS.USER_AB_TESTING_EXP_GROUP,
          operator: OPERATOR_FILTER.EQUALS,
          values: variants || [],
        }
      : null,
    amaAppIds && amaAppIds?.length > 0
      ? {
          member: USER_COUNT_CONSTANT.DIMENSIONS.USER_AB_TESTING_AMA_APP_ID,
          operator: OPERATOR_FILTER.EQUALS,
          values: amaAppIds || [],
        }
      : null,
  ].filter((f) => f !== null);

  const filterWithoutExpGroup = filters.filter(
    (f: any) => f?.member !== USER_COUNT_CONSTANT.DIMENSIONS.USER_AB_TESTING_EXP_GROUP,
  );
  const timeDimensions: any = [
    installDate
      ? {
          dimension: USER_COUNT_CONSTANT.DIMENSIONS.USER_AB_TESTING_INSTALL_DATE,
          dateRange: installDate,
        }
      : null,
  ].filter((f) => f !== null);
  const collapseItemsParams = getParamsFieldValue(typeTabs ?? TabsViewParamsEnum.COLLAPSE_ITEMS, "tabsConfig") ?? [];

  const isCorrectTabsView = collapseItemsParams.includes(chartKey);
  const isSkip = !isCorrectTabsView || !productToken || !abaId || !amaAppIds || variants?.length === 0;

  return {
    timeDimensions,
    filters,
    filterWithoutExpGroup,
    isSkip,
  };
};

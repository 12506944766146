import { SettingOutlined } from "@ant-design/icons";
import { TargetKey, useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form, Popover } from "antd";
import React from "react";

export const MetricParentTabName: React.FC<{ targetKey: string }> = ({ targetKey }) => {
  const [openPopover, setOpenPopover] = React.useState(false);

  const { form, remove, add } = useMetricPerformanceContext();

  const tabNameWatch = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_TAB_NAME], form);

  const tabNameByKey =
    tabNameWatch ??
    form.getFieldValue(ChartFormFieldsEnum.METRIC_TAB_ITEMS)?.find((item: any) => item.key === targetKey)?.[
      ChartFormFieldsEnum.METRIC_TAB_NAME
    ];

  const handleDuplicate = (targetKey: TargetKey) => {
    const _metricItem = form
      .getFieldValue(ChartFormFieldsEnum.METRIC_TAB_ITEMS)
      ?.find((item: any) => item.key === targetKey);

    if (!_metricItem) return;
    const { key, ...restOfMetricItem } = _metricItem;
    add(restOfMetricItem, restOfMetricItem?.[ChartFormFieldsEnum.METRIC_TAB_NAME]);
    setOpenPopover(false);
  };

  const ActionOptions = React.useMemo(() => {
    return [
      {
        label: <span>Duplicate metric</span>,
        key: "duplicate",
        onClick: (targetKey: TargetKey) => {
          handleDuplicate(targetKey);
        },
      },
      {
        label: <span className="text-red-500">Delete</span>,
        key: "delete",
        onClick: (targetKey: TargetKey) => {
          remove(targetKey);
          setOpenPopover(false);
        },
      },
    ];
  }, [remove]);

  return (
    <div className="relative ">
      <span className="pr-3">{tabNameByKey}</span>
      <div className="absolute -right-3 bottom-0 pr-1 metric-performance-tab-action">
        <Popover
          open={openPopover}
          onOpenChange={(open) => {
            setOpenPopover(open);
          }}
          content={() => {
            return (
              <div>
                {ActionOptions.map((_item) => {
                  return (
                    <div
                      className="px-2 py-1 cursor-pointer hover:bg-gray-100/90 rounded-[2px] duration-300"
                      key={_item.key}
                      onClick={() => {
                        targetKey && _item.onClick(targetKey);
                      }}
                    >
                      {_item.label}
                    </div>
                  );
                })}
              </div>
            );
          }}
          trigger="click"
        >
          <SettingOutlined onClick={() => setOpenPopover(true)} />
        </Popover>
      </div>
    </div>
  );
};

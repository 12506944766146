import { RangePresetsDefault } from "@src/constant/default-range-picker-presets";
import { useABContext } from "@src/contexts/ab-testing/ab-context";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { DatePicker, Form } from "antd";
import { FormInstance } from "antd/lib/form";

const { RangePicker } = DatePicker;
export const MetricTimeRange: React.FC<{ form: FormInstance<any> }> = ({ form }) => {
  const { updateSearchUrl } = useABContext();

  return (
    <Form.Item
      label="Time Range"
      name={ChartFormFieldsEnum.METRIC_TIME_RANGE}
      required
      rules={[
        {
          message: "Please select time range",
          required: true,
        },
      ]}
    >
      <RangePicker
        presets={RangePresetsDefault}
        style={{
          width: 300,
          backgroundColor: "white",
        }}
        onChange={() => {
          updateSearchUrl();
        }}
      />
    </Form.Item>
  );
};

export enum TabsKeyEnum {
  USER_DISTRIBUTION = "user-distribution",
  ENGAGEMENT = "engagement",
  METRIC_PERFORMANCE = "metric-performance",
  MONETIZATION = "monetization",
  TIME_IN_APP = "time-in-app",
  EVENT_BREAKDOWN = "event-breakdown",
  ADS = "ads",
  IAPS = "iaps",
}

export enum UserDistributionTabsKeyEnum {
  INSTALL_DATE = "install-date",
  MEDIA_SOURCE = "media-source",
  COUNTRY_CODE = "country-code",
  GENDER = "gender",
  AGE = "age",
}

export enum EngagementTabsKeyEnum {
  PLAY_TIME = "play-time",
  TIME_IN_APP = "time-in-app",
  SESSION_COUNT = "session-count",
  USER_HISTOGRAM = "user_histogram",
  EVENT_COUNT_BREAKDOWN = "event_count_breakdown",
}

export enum MonetizationTabsKeyEnum {
  AD_VALUE = "ad-value",
  AD_IMPRESSION = "iap-impression",
  E_CPM = "e-CPM",
  IAPS_VALUE = "iaps-value",
  PAY_RATE = "pay-rate",
}

import { NavigateFunction } from "react-router-dom";

export class URLParamsManager {
  public getParam(key: string) {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(key);
  }
  public getParams() {
    const searchParams = new URLSearchParams(window.location.search);
    return Object.fromEntries(searchParams.entries());
  }

  public updateParams(newParams: Record<string, string>, navigate?: NavigateFunction) {
    const searchParams = new URLSearchParams(window.location.search);
    for (const [key, value] of Object.entries(newParams)) {
      if (value === null || value === undefined) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value);
      }
    }
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    if (navigate) {
      navigate(newUrl, {
        replace: true,
      });
    } else {
      window.history.replaceState({ path: newUrl }, "", newUrl);
    }
  }

  public addParam(key: string, value: string, navigate?: NavigateFunction) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    if (navigate) {
      navigate(newUrl, {
        replace: true,
      });
    } else {
      window.history.replaceState({ path: newUrl }, "", newUrl);
    }
  }

  public deleteParam(key: string, navigate?: NavigateFunction) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(key);
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    if (navigate) {
      navigate(newUrl, {
        replace: true,
      });
    } else {
      window.history.replaceState({ path: newUrl }, "", newUrl);
    }
  }
}

import React from "react";
import { TimeDimension } from "@cubejs-client/core";

// types
import { IMemberDimensions } from "@src/types/query-builder";

// configs
import { GRANULARITIES } from "@src/config/query-builder";

// helpers
import { mapMemberToTreeNode } from "@src/helpers/tree-select-member";

// components
import MemberGroupBase from "./member-group-base";
import MemberTagDimension from "./member-tag-dimensions";
import { config } from "@src/config";
import { QueryMemberGroupDimensionsProps } from "@src/types/query-builder-firebase";
import { QueryDimension } from "@src/types/cube";
import { convertQueryOrderToArray } from "@src/util/convert-query-order-to-array";

function MemberGroupDimensions({
  title,
  description = "Select item to display",
  dimensions,
  availableMembers,
  timeDimensions,
  updateTimeMethods,
  updateQuery,
  query,
  isFetchingMeta,
}: QueryMemberGroupDimensionsProps) {
  const queryOrder = React.useMemo(() => {
    return convertQueryOrderToArray(query?.order);
  }, [query.order]);

  const cubOptions = React.useMemo(() => mapMemberToTreeNode(availableMembers), [availableMembers]);

  const getDefaultCheckedKeys = React.useMemo(() => {
    const keyDimensions = query?.dimensions ? query.dimensions.map((m: string) => m) : [];
    const keyTimeDimensions = query?.timeDimensions
      ? query.timeDimensions.filter((m) => !!m.granularity).map((m) => m.dimension)
      : [];
    return [...keyDimensions, ...keyTimeDimensions];
  }, [query.dimensions, query.timeDimensions]);

  function addMembers(
    dimensionsSelected: IMemberDimensions[],
    timeDimensionsSelected: any[],
    membersUnSelected: React.Key[],
    timeDimensionsUnSelected: any[],
  ) {
    let dimensionsUpdatedWith: string[] = [];
    const queryTimeDimensions = query?.timeDimensions || [];
    const newOrders = queryOrder.filter((order: any) => !membersUnSelected.includes(order[0])); // update order when unselected member

    if (dimensionsSelected.length > 0) {
      dimensionsUpdatedWith = dimensionsSelected.map((m) => m.name);
    }

    let newTimeDimension = query.timeDimensions;
    if (timeDimensionsUnSelected.length > 0) {
      newTimeDimension = newTimeDimension?.filter((m) => !timeDimensionsUnSelected.some((t) => m.dimension === t.name));
    }
    if (timeDimensionsSelected.length > 0) {
      newTimeDimension = newTimeDimension?.concat(
        timeDimensionsSelected.map((m) => {
          const timeItem = queryTimeDimensions.find((q) => q.dimension === m.name);
          return {
            dimension: m.name,
            granularity: timeItem ? timeItem.granularity || GRANULARITIES[0].name : GRANULARITIES[0].name,
          };
        }) as TimeDimension[],
      );
    }

    updateQuery({
      dimensions: dimensionsUpdatedWith,
      order: newOrders,
      timeDimensions: newTimeDimension,
    });
  }

  function onRemoveMembers(member: QueryDimension) {
    if (member.type === "time") {
      updateQuery({
        timeDimensions: query.timeDimensions?.filter((m) => m.dimension !== member.name) ?? [],
      });
    } else {
      const dimensions = JSON.parse(JSON.stringify(query.dimensions)) as string[];
      const indexDimensions = dimensions.findIndex((dim: string) => dim === member.name);
      if (indexDimensions > -1) {
        dimensions.splice(indexDimensions, 1);
        updateQuery({
          dimensions,
        });
      }
    }

    // update query order
    const indexTimeInOrder = queryOrder.findIndex((order: any) => order[0] === member.name);
    if (indexTimeInOrder > -1) {
      queryOrder.splice(indexTimeInOrder, 1);
      setTimeout(() => {
        updateQuery({
          order: queryOrder,
        });
      });
    }
  }

  const memberTimeDimensions = React.useMemo(() => {
    if (timeDimensions.length === 0) return [];
    return timeDimensions.map((dim) => {
      return {
        index: dim.index,
        name: dim.dimension.name,
        title: dim.dimension.title,
        type: dim.dimension.type,
        shortTitle: dim.dimension.shortTitle,
        suggestFilterValues: dim.dimension.suggestFilterValues,
        isVisible: dim.dimension.isVisible,
        public: dim.dimension.public,
        primaryKey: dim.dimension.primaryKey,
        granularity: dim.granularity,
      };
    });
  }, [timeDimensions]);

  const getDetailDimensionFromActionMembers = (members: string[]) => {
    return members.reduce(
      (acc, curItem) => {
        const missingItem = availableMembers.find((m) => m.name === curItem);
        if (missingItem?.type === "time") {
          acc.timeDimensions.push(missingItem as never);
        } else {
          acc.dimensions.push(missingItem as never);
        }
        return acc;
      },
      {
        dimensions: [],
        timeDimensions: [],
      },
    );
  };
  return (
    <MemberGroupBase
      title={title}
      dataSource={cubOptions}
      query={query}
      isFetchingMeta={isFetchingMeta}
      getDefaultCheckedKeys={getDefaultCheckedKeys}
      onOk={(selectedMembers, membersUnSelected = []) => {
        const { dimensions, timeDimensions } = getDetailDimensionFromActionMembers(selectedMembers);
        const { timeDimensions: timeDimensionsUnSelected } = getDetailDimensionFromActionMembers(
          membersUnSelected as string[],
        );

        addMembers(dimensions, timeDimensions, membersUnSelected, timeDimensionsUnSelected);
      }}
    >
      {dimensions.length === 0 && timeDimensions.length === 0 && <>{description}</>}

      {/* show dimensions */}
      <div className="flex flex-wrap">
        <MemberTagDimension
          members={dimensions}
          timeDimensions={timeDimensions}
          updateTimeMethods={updateTimeMethods}
          onRemoveMembers={onRemoveMembers}
        />

        {/* show time dimensions */}
        <MemberTagDimension
          members={memberTimeDimensions.filter((m) => m.granularity)}
          timeDimensions={timeDimensions}
          updateTimeMethods={updateTimeMethods}
          onRemoveMembers={onRemoveMembers}
        />
      </div>
    </MemberGroupBase>
  );
}

export default MemberGroupDimensions;

import React from "react";
import { Authenticated, Refine } from "@refinedev/core";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import routerBindings, { CatchAllNavigate, NavigateToResource } from "@refinedev/react-router-v6";
import { ErrorComponent, useNotificationProvider } from "@refinedev/antd";
import dataProvider from "@refinedev/simple-rest";
import dataProviderNestJsx from "@refinedev/nestjsx-crud";

import { ReactFlowProvider } from "reactflow";

// antd icons
import { FireOutlined, FundOutlined, PicLeftOutlined, PieChartOutlined, ToolOutlined } from "@ant-design/icons";

// components

// configs
import { PAGE_URL, config } from "./config";

// contexts
import { AppContextProvider } from "@src/contexts/app-context";
import { MultiStepQueryContextProvider } from "./contexts/multi-step-query-context";

// pages
import { FunnelAnalysis } from "@src/pages/funnel-analysis";
import { PathAnalysis } from "@src/pages/path-analysis";
import { DrillerAnalysis } from "@src/pages/driller-analysis";
import { MultiStepQuery } from "@src/pages/multi-step-query";
import { SavedExploration } from "@src/pages/workpsace/saved-exploration";
import { Error401 } from "@src/pages/error/401";
import { Error403 } from "@src/pages/error/403";
import { Login } from "@src/pages/login";
import { Funnel } from "@src/pages/advanced-analytics/funnel";
import { ListExplore } from "./pages/explore/list";
import { ConfigProvider } from "antd";
import { themeConfig } from "./config/antd";
import { Layout } from "./components";
import { AdminAuth } from "./auth/AdminAuth";
import { ProductListing } from "./pages/admin/product/list";
import enUS from "antd/lib/locale/en_US";
import { FirebaseExploreCreate } from "./pages/explore/component/firebase/create";
import { FirebaseExploreEdit } from "./pages/explore/component/firebase/edit";
import { ExploreContextProvider } from "./contexts/explore-context";
import { ABTestExplore } from "./pages/ab-test-explore/list";
import { useAuth0Provider } from "./libs/auth0-provider";
import { LoadingFullScreen } from "./components/loading/loading-full-screen";
import { FirebaseExploreShareView } from "./pages/explore/component/firebase/share-view";
import { ABContextProvider } from "./contexts/ab-testing/ab-context";

function App() {
  const { auth0Provider, isLoading, axios } = useAuth0Provider();
  if (isLoading) {
    return <LoadingFullScreen />;
  }
  return (
    <BrowserRouter>
      <ConfigProvider locale={enUS} theme={themeConfig}>
        <Refine
          dataProvider={{
            default: dataProvider(config.BACKEND_URL, axios),
            nestjsx: dataProviderNestJsx(config.NESTJS_URL + "/api"),
          }}
          notificationProvider={useNotificationProvider}
          routerProvider={routerBindings}
          authProvider={auth0Provider}
          resources={[
            {
              name: "Exploration",
              meta: {
                icon: <PicLeftOutlined />,
              },
            },
            {
              name: "Firebase",
              list: "/explorations",
              edit: "/explorations/firebase/edit/:id",
              create: "/explorations/firebase/create",
              meta: {
                parent: "Exploration",
                icon: <FundOutlined />,
                label: "Firebases",
              },
            },
            {
              name: "A/B Test Exploration",
              list: "/ab-test-explorations",
              meta: {
                parent: "Exploration",
                icon: <PieChartOutlined />,
                label: "A/B Test Exploration",
              },
            },
            {
              name: "Admin",
              meta: {
                icon: <ToolOutlined />,
              },
            },
            {
              name: "Products",
              list: "admin/products",
              meta: {
                parent: "Admin",
                icon: <FireOutlined />,
              },
            },
          ]}
          options={{
            syncWithLocation: true,
            warnWhenUnsavedChanges: true,
            useNewQueryKeys: true,
          }}
        >
          <Routes>
            <Route
              element={
                <Authenticated key="authenticated-inner" fallback={<CatchAllNavigate to={PAGE_URL.LOGIN} />}>
                  <AppContextProvider>
                    <Layout>
                      <Outlet />
                    </Layout>
                  </AppContextProvider>
                </Authenticated>
              }
            >
              <Route index element={<NavigateToResource resource="Exploration" />} />
              <Route
                element={
                  <AdminAuth>
                    <Outlet />
                  </AdminAuth>
                }
                path="/admin"
              >
                <Route path={"products"} element={<ProductListing />} />
              </Route>

              <Route path={PAGE_URL.FUNNEL_ANALYSIS} element={<FunnelAnalysis />} />
              <Route path={PAGE_URL.PATH_ANALYSIS} element={<PathAnalysis />} />
              <Route path={PAGE_URL.DATA_DRILLER} element={<DrillerAnalysis />} />
              <Route
                path={PAGE_URL.MULTI_STEP_QUERY}
                element={
                  <ReactFlowProvider>
                    <MultiStepQueryContextProvider>
                      <MultiStepQuery />
                    </MultiStepQueryContextProvider>
                  </ReactFlowProvider>
                }
              />
              <Route path={PAGE_URL.WORKSPACE.SAVED_EXPLORATION} element={<SavedExploration />} />
              <Route path={PAGE_URL.ADVANCED_ANALYTICS.FUNNEL} element={<Funnel />} />
              <Route path="*" element={<ErrorComponent />} />
              <Route path="/explorations">
                <Route index element={<ListExplore />} />
                <Route
                  path="firebase/create"
                  element={
                    <ExploreContextProvider key="explore-context-create">
                      <FirebaseExploreCreate />
                    </ExploreContextProvider>
                  }
                />
                <Route
                  path="firebase/edit/:id"
                  element={
                    <ExploreContextProvider key="explore-context-edit">
                      <FirebaseExploreEdit />
                    </ExploreContextProvider>
                  }
                />
              </Route>
              <Route
                path="shared-explorations/firebase/:id"
                element={
                  <ExploreContextProvider key="explore-context-share-view">
                    <FirebaseExploreShareView />
                  </ExploreContextProvider>
                }
              />
              <Route path="ab-test-explorations">
                <Route
                  index
                  element={
                    <ABContextProvider>
                      <ABTestExplore />
                    </ABContextProvider>
                  }
                />
              </Route>
              <Route path="*" element={<ErrorComponent />} />
            </Route>

            <Route
              element={
                <Authenticated key="authenticated-outer" fallback={<Outlet />}>
                  <NavigateToResource />
                </Authenticated>
              }
            >
              <Route path={PAGE_URL.LOGIN} element={<Login />} />
            </Route>

            {/* Error Route */}
            <Route
              path={PAGE_URL.ERROR}
              element={
                <Authenticated key="authenticated-inner" fallback={<CatchAllNavigate to={PAGE_URL.LOGIN} />}>
                  <Outlet />
                </Authenticated>
              }
            >
              <Route path={PAGE_URL.ERROR_401} element={<Error401 />} />
              <Route path={PAGE_URL.ERROR_403} element={<Error403 />} />
            </Route>
          </Routes>
        </Refine>
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;

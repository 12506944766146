import { CopyOutlined } from "@ant-design/icons";
import { BaseKey } from "@refinedev/core";
import { useDuplicateFirebaseExplore } from "@src/hooks/fb-explore/use-duplicate-firebase-explore";
import { Button, Popconfirm, Tooltip } from "antd";
import { FormInstance } from "antd/lib/form";

type DuplicateButtonProps = {
  id?: string | BaseKey;
  form: FormInstance<any>;
};
export const DuplicateButton: React.FC<DuplicateButtonProps> = ({ id, form }) => {
  const { handleDuplicate } = useDuplicateFirebaseExplore({
    id: id as any,
    replace: false,
    form,
  });

  const onDuplicate = async () => {
    handleDuplicate({});
  };
  return (
    <Tooltip title="Duplicate">
      <Popconfirm title="Are you sure duplicate this exploration?" onConfirm={() => onDuplicate()}>
        <Button icon={<CopyOutlined />} size="small" />
      </Popconfirm>
    </Tooltip>
  );
};

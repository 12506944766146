import { Empty } from "antd";
import { ReactNode } from "react";

export const EChartWrapper: React.FC<{ isEmpty?: boolean; children: ReactNode; description?: string }> = ({
  isEmpty = false,
  children,
  description,
}) => {
  if (isEmpty) {
    return (
      <div className="w-full h-[150px] flex items-center justify-center ">
        <Empty description={description ?? "No data"} />
      </div>
    );
  }
  return <div className="h-[600px] overflow-y-scroll">{children}</div>;
};

import { Create, ListButton, SaveButton, useForm } from "@refinedev/antd";
import { useExploreContext } from "@src/contexts/explore-context";
import React from "react";
import { useCreate, useNavigation } from "@refinedev/core";
import { Card, Form } from "antd";
import { GeneralInfoWrapper } from "./form/general-info-wrapper";
import ExploreQueryNew from "@src/pages/dashboard/components/explore-builder-new";
import { ChartResultProvider } from "./form/chart-result-provider";
import { QueryBuilderChartLayout } from "@src/layouts/firebase-explore/query-builder-chart-layout";

export const FirebaseExploreCreate: React.FC = () => {
  const { push, replace } = useNavigation();
  const { formProps } = useForm({
    resource: "explorations",
    dataProviderName: "nestjsx",
    redirect: "list",
  });
  const { query, chartType, pivotConfig, product, form: formContext } = useExploreContext();

  const form = formContext ?? formProps.form;
  const { mutateAsync: createNewFirebaseExplore } = useCreate();

  const handleFinish = async (e: any) => {
    form?.validateFields();
    const payload = {
      title: e.name,
      description: e?.description ?? "",
      product_code: e.product_code,
      is_public: true,
      chart_type: chartType,
      pivot_config: pivotConfig,
      // ...query, // include dimensions/measures/segments/limit/orders
      filter: query?.filters,
      dimensions: query?.dimensions,
      measures: query?.measures,
      segments: query?.segments,
      order: query?.order,
      time_dimensions: query?.timeDimensions,
      limit: 100,
    };

    createNewFirebaseExplore({
      resource: `exploration/fb-exploration`,
      values: payload,
      successNotification: () => {
        return {
          type: "success",
          message: "Exploration created  successfully",
        };
      },
      errorNotification: () => {
        return {
          type: "error",
          message: "Exploration created  error",
        };
      },
    }).then((res) => {
      const newId = res?.data?.id;
      replace(`/explorations/firebase/edit/${newId}`, {});
    });
  };
  const platformWatch = Form.useWatch("platform", form);
  const isEmptyPlatform = !platformWatch || platformWatch.length === 0;
  return (
    <Create
      title="Create exploration"
      headerButtons={<ListButton onClick={() => push("/")}>Explorations</ListButton>}
      contentProps={{
        className: "bg-transparent shadow-none card-custom",
      }}
      footerButtonProps={{
        hidden: true,
      }}
      wrapperProps={{
        className: "base-custom-container",
      }}
    >
      <Form preserve layout="vertical" {...formProps} form={form} onFinish={handleFinish}>
        <Card size="small" title={"General Information"} className="mb-2">
          <div className="flex mb-4 ">
            <div className="w-3/4">
              <GeneralInfoWrapper form={form} mode="create" />
            </div>
            <div className="w-1/4">
              <SaveButton disabled={isEmptyPlatform} htmlType="submit" className=" absolute bottom-10 right-2">
                Save
              </SaveButton>
            </div>
          </div>
        </Card>

        {product && (
          <QueryBuilderChartLayout
            queryBuilderComponent={<ExploreQueryNew />}
            showResultComponent={<ChartResultProvider mode="create" form={form} />}
          />
        )}
      </Form>
    </Create>
  );
};

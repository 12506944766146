import React from "react";

// types
import { IMemberBase } from "@src/types/query-builder";

// helpers
import { mapMemberToTreeNode } from "@src/helpers/tree-select-member";

// components
import MemberGroupBase from "./member-group-base";
import MemberTagBase from "./member-tag-base";
import { QueryMemberGroupSegmentsProps } from "@src/types/query-builder-firebase";
import { useExploreContext } from "@src/contexts/explore-context";

function MemberGroupSegments({
  title,
  description = "Select item to display",
  availableMembers,
  members,
  updateMethods,
  updateQuery,
  query,
  isFetchingMeta,
}: QueryMemberGroupSegmentsProps) {
  const { manualFetchDryRun } = useExploreContext();

  const cubOptions = React.useMemo(() => mapMemberToTreeNode(availableMembers), [availableMembers]);
  const getDefaultCheckedKeys = React.useMemo(
    () => (query?.segments ? query.segments.map((m: string) => m) : []),
    [query.segments],
  );

  function onRemoveMember(member: IMemberBase) {
    updateMethods?.remove(member);
  }

  return (
    <MemberGroupBase
      title={title}
      dataSource={cubOptions}
      query={query}
      isFetchingMeta={isFetchingMeta}
      getDefaultCheckedKeys={getDefaultCheckedKeys}
      onOk={(selectedMembers: string[]) => {
        updateQuery({ segments: selectedMembers });
        manualFetchDryRun({
          query: {
            ...query,
            segments: selectedMembers,
          },
        });
      }}
    >
      <div className="flex flex-wrap">
        {members.length > 0 ? (
          <div className="flex flex-wrap mr-2">
            {members.map((member) => {
              return (
                <div key={member.title} className="mb-2 mr-2">
                  <MemberTagBase member={member} onRemoveMember={onRemoveMember} />
                </div>
              );
            })}
          </div>
        ) : (
          <>{description}</>
        )}
      </div>
    </MemberGroupBase>
  );
}

export default MemberGroupSegments;

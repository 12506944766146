import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form } from "antd";
import { FormInstance } from "antd/lib/form";
import { useDebounce } from "../use-debounce";
import { config } from "@src/config";
import { isArrayAndNotEmpty } from "@src/util/common/array";
import React from "react";
import { useLoadAbExploration } from "@src/hooks/ab-testing/use-ab-exploration-get-result";
import { SupportedModelAlias } from "@src/constant/ab-testing/ad-testing-exploration.enum";

type UseGetExtraValueFieldProps = {
  form: FormInstance;
  dimension: string[];
};

export const useGetExtraValueField = ({ form, dimension }: UseGetExtraValueFieldProps) => {
  const productCodeWatch = Form.useWatch(FilterFormFieldsEnum.PRODUCT_CODE, form);

  const abaIdWatch = Form.useWatch(FilterFormFieldsEnum.ABA_ID, form);
  const abaIdWatchDebounce = useDebounce(abaIdWatch, config.DEBOUNCE_TIME.MEDIUM_DELAY);

  const variantWatch = Form.useWatch(FilterFormFieldsEnum.VARIANTS, form);
  const variantsDebounce = useDebounce(variantWatch, config.DEBOUNCE_TIME.MEDIUM_DELAY);

  const amaAppIdWatch = Form.useWatch(FilterFormFieldsEnum.AMA_APP_IDS, form);
  const amaAppIdDebounce = useDebounce(amaAppIdWatch, config.DEBOUNCE_TIME.MEDIUM_DELAY);

  const filters: any = React.useMemo(() => {
    return [
      abaIdWatchDebounce
        ? {
            member: "aba_id",
            operator: "equals",
            values: [abaIdWatchDebounce.toString()],
          }
        : null,
      variantsDebounce && variantsDebounce?.length > 0
        ? {
            member: "exp_group",
            operator: "equals",
            values: variantsDebounce,
          }
        : null,
      amaAppIdDebounce && amaAppIdDebounce?.length > 0
        ? {
            member: "ama_app_id",
            operator: "equals",
            values: amaAppIdDebounce,
          }
        : null,
    ].filter((f) => !!f);
  }, [abaIdWatchDebounce, amaAppIdDebounce, variantsDebounce]);

  const isSkip = !productCodeWatch || !abaIdWatchDebounce || !isArrayAndNotEmpty(amaAppIdDebounce);

  const { result, isLoading } = useLoadAbExploration({
    productCode: productCodeWatch,
    modelAlias: SupportedModelAlias.USER_INFO,
    query: {
      dimensions: dimension,
      measures: ["user_count"],
      filters,
      limit: 10000,
      order: [["user_count", "desc"]],
    },
    isSkip,
  });

  const getOptions = React.useCallback(
    (
      _dimension: string,
      cb?: {
        labelMapping?: (item: any) => string;
        valueMapping?: (item: any) => string;
      },
    ) => {
      const { labelMapping, valueMapping } = cb || {};

      return result?.map((item: any) => {
        const data = item[_dimension];

        return {
          label: labelMapping ? labelMapping(data) : data,
          value: valueMapping ? valueMapping(data) : data,
          ...item,
        };
      });
    },
    [result],
  );

  const isResultEmpty = !!result;

  const removeRedundantData = React.useCallback(
    ({ field_name, newOptions }: { field_name: string; newOptions: any[] | undefined }) => {
      if (isResultEmpty) return;
      const previousFormValues = form.getFieldValue(field_name);
      if (!previousFormValues || !newOptions) return;

      form.setFieldsValue({
        [field_name]: previousFormValues?.filter((v: string) => newOptions?.some((o: any) => o.value === v)),
      });
    },
    [form, isResultEmpty],
  );

  return { isLoading, getOptions, removeRedundantData };
};

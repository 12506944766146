import { InputSkeleton } from "@src/components/input-skeleton";
// import { getGroupFieldValue } from "@src/util/ab-testing/params";
import { Form, Input } from "antd";
import { FilterFormFieldsEnum } from "../../enum";
import { FormInstance } from "antd/lib/form";
import { FilterFormInstanceType } from "@src/contexts/ab-testing/ab-context";

type InputSkeletonLayoutProps = {
  children: React.ReactNode;
  width: number;
  label: string;
  required?: boolean;
  isLoading: boolean;
  form: FormInstance<FilterFormInstanceType>;
  placeholder: string;
};
// const paramsConfig = getGroupFieldValue("filterForm");
export const InputSkeletonLayout: React.FC<InputSkeletonLayoutProps> = ({
  children,
  width,
  isLoading,
  form,
  label,
  required,
  placeholder,
}) => {
  const productToken = Form.useWatch(FilterFormFieldsEnum.PRODUCT_TOKEN, form);
  // const isFirstLoadingWithExistingData = paramsConfig && (!productToken || isLoading);

  return (
    <div
      style={{
        width: `${width}px`,
      }}
      className="mx-1 "
    >
      {!productToken ? (
        <div>
          <Form.Item label={label} required={required}>
            <Input disabled placeholder={placeholder} />
          </Form.Item>
        </div>
      ) : (
        <div>
          <div
            style={{
              display: isLoading ? "block" : "none",
            }}
          >
            <Form.Item label={label} required={required}>
              <InputSkeleton />
            </Form.Item>
          </div>
          <div
            style={{
              display: isLoading ? "none" : "block",
            }}
          >
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

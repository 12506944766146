import { InputNumber, Space, Typography, Tabs, Table } from "antd";
import React from "react";
import { mappingQueryUserCount } from "@src/util/ab-testing/user-count/mapping-query-user-count";
import { UserCountItemProps } from ".";
import { ChartLayout } from "../../layout/chart-layout";
import { useChartWrapperContext } from "@src/contexts/ab-testing/chart-wrapper-context";
import { useLoadAbExploration } from "@src/hooks/ab-testing/use-ab-exploration-get-result";
import { EChartWrapper } from "../../layout/echart-wrapper";
import ReactECharts from "echarts-for-react";
import { SupportedModelAlias } from "@src/constant/ab-testing/ad-testing-exploration.enum";
import { IQuery, IQueryOptions } from "@src/types/ab-testing-exploration";
import { useGetGeneralField } from "@src/hooks/ab-testing/use-get-general-field";

const { Text } = Typography;

const UserCountByCountryCode: React.FC<UserCountItemProps> = ({ form, chartKey }) => {
  const { updateChartState } = useChartWrapperContext();
  const generalFieldValues = useGetGeneralField({ form });
  const { productCode, explorationFilters } = generalFieldValues;
  const { isSkip } = mappingQueryUserCount(generalFieldValues, chartKey);
  const [numberOfCountry, setNumberOfCountry] = React.useState(5);

  const jsonQuery: IQuery = {
    dimensions: ["exp_group", "country_code"],
    measures: ["user_count"],
    filters: explorationFilters,
    limit: 10000,
    order: [
      ["exp_group", "asc"],
      ["user_count", "desc"],
    ],
  };

  const queryOptions: IQueryOptions = {
    // TODO: get form value instead
    cohort_date: "first_signal_date",
  };

  const modelAlias = SupportedModelAlias.USER_INFO;

  const { result, meta, renderedQuery, isLoading, elapsed } = useLoadAbExploration({
    productCode,
    modelAlias,
    query: jsonQuery,
    options: queryOptions,
  });

  const expGroupGroupedResult = result ? Object.groupBy(result, ({ exp_group }) => exp_group) : {};

  const maxNumberOfCountry = result
    ? Math.max(...Object.values(expGroupGroupedResult).map((d) => (d ? d.length : 0)))
    : undefined;

  const maxVal = result ? Math.max(...result?.map(({ user_count }) => user_count)) : 0;

  const sourceDataSet =
    Object.keys(expGroupGroupedResult)
      ?.map((d: any) => {
        // return d?.series
        return expGroupGroupedResult[d]
          ?.map((item: any) => {
            return {
              x: item["country_code"],
              y: d,
              value: item["user_count"],
            };
          })
          ?.slice(0, numberOfCountry);
      })
      ?.flat(1)
      ?.sort((a: any, b: any) => b["value"] - a["value"]) || [];

  const option: echarts.EChartsOption = {
    tooltip: {
      position: "top",
    },
    grid: {
      height: "50%",
      top: "10%",
    },
    xAxis: {
      type: "category",
      axisLabel: {
        rotate: 30,
      },
      splitArea: {
        show: true,
      },
    },
    yAxis: {
      type: "category",
      splitArea: {
        show: true,
      },
    },
    visualMap: {
      min: 0,
      max: maxVal,
      calculable: true,
      orient: "horizontal",
      left: "center",
      bottom: "15%",
    },
    dataset: [
      {
        source: sourceDataSet,
      },
    ],
    series: [
      {
        name: "User country",
        type: "heatmap",
        label: {
          show: true,
          formatter: (params: any) => {
            return `${params.value?.value ?? 0}`;
          },
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
    gradientColor: ["#4bcffa", "#0fbcf9", "#6a89cc", "#4a69bd", "#1e3799"],
  };

  const columns = meta?.schema.map((f, idx) => {
    return {
      key: f.name,
      dataIndex: f.name,
      title: f.name,
    };
  });

  const dataSource = result?.map((item, idx) => {
    return {
      id: idx,
      ...item,
    };
  });

  React.useEffect(() => {
    if (isSkip) return;
    updateChartState({
      chartKey,
      modelAlias,
      queryOption: queryOptions,
      jsonQuery,
      dataSource,
      renderedQuery,
      meta,
      elapsed,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dataSource), renderedQuery, isSkip]);

  return (
    <ChartLayout loading={!!isLoading}>
      <Space direction="horizontal">
        <Text>Top </Text>
        <InputNumber
          defaultValue={numberOfCountry}
          min={1}
          max={maxNumberOfCountry}
          onChange={(v) => {
            if (v) {
              setNumberOfCountry(v);
            }
          }}
        />
        <Text>Countries</Text>
      </Space>
      <Tabs
        className="w-full h-full "
        items={[
          {
            key: "chart",
            label: "Chart",
            children: (
              <EChartWrapper isEmpty={result === undefined || (result && result.length === 0)}>
                <ReactECharts option={option} style={{ height: "100%" }} />
                {/* <ReactECharts option={dummyOptions} style={{ height: "100%" }} /> */}
              </EChartWrapper>
            ),
          },
          {
            key: "raw-data",
            label: "Raw data",
            children: (
              <EChartWrapper isEmpty={result === undefined || (result && result.length === 0)}>
                <Table
                  id="id"
                  rowKey="id"
                  sticky={{
                    offsetHeader: 0,
                  }}
                  size="small"
                  dataSource={dataSource}
                  columns={columns}
                />
              </EChartWrapper>
            ),
          },
        ]}
      />
    </ChartLayout>
  );
};
export const UserCountByCountryCodeMemo = React.memo(UserCountByCountryCode);

import { FilterFormInstanceType } from "@src/contexts/ab-testing/ab-context";
import { FormInstance } from "antd/lib/form";
import { ChipsListing } from "./chips-listing";
import { Form } from "antd";
import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";

export const ChipWrapper: React.FC<{ form: FormInstance<FilterFormInstanceType> }> = ({ form }) => {
  const isShowChip = Form.useWatch(FilterFormFieldsEnum.IS_SHOW_CHIP, form);
  return (
    <div
      style={{
        display: isShowChip ? "block" : "none",
      }}
    >
      <ChipsListing form={form} />
    </div>
  );
};

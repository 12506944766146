import { RangePresetsDefault } from "@src/constant/default-range-picker-presets";
import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { DatePicker, DatePickerProps, Form } from "antd";
import { FormInstance } from "antd/lib/form";

const { RangePicker } = DatePicker;
export const ExtraInstallDate: React.FC<{ form: FormInstance }> = () => {
  const disabled31DaysDate: DatePickerProps["disabledDate"] = (current, { from }) => {
    if (from) {
      return Math.abs(current.diff(from, "days")) >= 31;
    }
    return false;
  };

  return (
    <Form.Item
      className="w-[50%] max-w-[600px]"
      labelCol={{
        span: 6,
      }}
      name={FilterFormFieldsEnum.EXTRA_INSTALL_DATE}
      label="Install Date"
    >
      <RangePicker
        presets={RangePresetsDefault}
        style={{
          width: 400,
        }}
      />
    </Form.Item>
  );
};

import { Button } from "antd";
import { ChartActionProps } from ".";
import { DeliveredProcedureOutlined } from "@ant-design/icons";

export const CreateExplorationButton: React.FC<ChartActionProps> = () => {
  return (
    <Button type="text" disabled className="w-full flex justify-start items-center">
      <DeliveredProcedureOutlined className="pb-1 text-[16px]" />
      Create exploration
    </Button>
  );
};

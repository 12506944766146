import { useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form, TreeSelect } from "antd";
import { abTestingCubeOptions } from "../../mock/ab-testing-cube-options";
import { mappingTreeData } from "./extra";

const treeDataMapping = mappingTreeData(abTestingCubeOptions);

export const MetricMeasure: React.FC<{ targetKey: string }> = ({ targetKey }) => {
  const { handleUpdateMetricTabItems } = useMetricPerformanceContext();
  return (
    <Form.Item
      label="Measure"
      name={[targetKey, ChartFormFieldsEnum.METRIC_MEASURE]}
      required
      rules={[
        {
          message: "Please select a measure",
          required: true,
        },
      ]}
    >
      <TreeSelect
        placeholder="Metric Breakdown"
        showSearch
        treeData={treeDataMapping}
        style={{ width: 500 }}
        treeLine={{
          showLeafIcon: false,
        }}
        onChange={() => {
          handleUpdateMetricTabItems(targetKey, ChartFormFieldsEnum.METRIC_MEASURE);
        }}
      />
    </Form.Item>
  );
};

import { Table, Tabs } from "antd";
import React from "react";
import { mappingQueryUserCount } from "@src/util/ab-testing/user-count/mapping-query-user-count";
import { useLoadAbExploration } from "@src/hooks/ab-testing/use-ab-exploration-get-result";
import { UserCountItemProps } from ".";
import ReactECharts from "echarts-for-react";
import { useChartWrapperContext } from "@src/contexts/ab-testing/chart-wrapper-context";
import { ChartLayout } from "../../layout/chart-layout";
import { EChartWrapper } from "../../layout/echart-wrapper";
import { SupportedModelAlias } from "@src/constant/ab-testing/ad-testing-exploration.enum";
import { IQuery, IQueryOptions } from "@src/types/ab-testing-exploration";
import { useGetGeneralField } from "@src/hooks/ab-testing/use-get-general-field";

const UserCountByInstallDate: React.FC<UserCountItemProps> = ({ form, chartKey }) => {
  const { updateChartState } = useChartWrapperContext();

  const valuesOfGeneralField = useGetGeneralField({ form });
  const { productCode, explorationFilters } = valuesOfGeneralField;
  const { isSkip } = mappingQueryUserCount(valuesOfGeneralField, chartKey);

  const jsonQuery: IQuery = {
    dimensions: ["exp_group", "install_date_tzutc"],
    measures: ["user_count"],
    filters: explorationFilters,
    limit: 10000,
    order: [
      ["exp_group", "asc"],
      ["install_date_tzutc", "asc"],
    ],
  };

  const queryOptions: IQueryOptions = {
    // TODO: get form value instead
    cohort_date: "first_signal_date",
  };

  const modelAlias = SupportedModelAlias.USER_INFO;

  const { result, meta, renderedQuery, isLoading, elapsed } = useLoadAbExploration({
    productCode,
    modelAlias,
    query: jsonQuery,
    options: queryOptions,
  });

  const groupedResult = result ? Object.groupBy(result, ({ exp_group }) => exp_group) : {};
  const dataSetMapping: any = Object.keys(groupedResult).map((seriesName) => {
    return {
      source: groupedResult[seriesName],
    };
  });
  const seriesMapping: echarts.EChartsOption["series"] = Object.keys(groupedResult).map((seriesName, idx) => {
    return {
      type: "line",
      datasetIndex: idx,
      name: seriesName,
      encode: { x: "install_date_tzutc", y: "user_count" },
    };
  });

  const option: echarts.EChartsOption = {
    animationDuration: 1000,
    tooltip: {
      trigger: "axis",
    },
    grid: {
      bottom: 100,
      top: 10,
      right: 100,
    },
    dataZoom: [
      {
        type: "slider",
      },
      {
        type: "inside",
      },
    ],
    dataset: dataSetMapping,
    xAxis: { type: "time" },
    yAxis: {},
    series: seriesMapping,
    legend: {
      orient: "vertical",
      align: "right",
      right: 10,
      bottom: 200,
    },
  };

  const columns = meta?.schema.map((f, idx) => {
    return {
      key: f.name,
      dataIndex: f.name,
      title: f.name,
    };
  });

  const dataSource = result?.map((item, idx) => {
    return {
      id: idx,
      ...item,
    };
  });

  React.useEffect(() => {
    if (isSkip) return;
    updateChartState({
      chartKey,
      modelAlias,
      queryOption: queryOptions,
      jsonQuery,
      dataSource,
      renderedQuery,
      meta,
      elapsed,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dataSource), renderedQuery, isSkip]);

  return (
    <ChartLayout loading={!!isLoading}>
      <Tabs
        className="w-full h-full "
        items={[
          {
            key: "chart",
            label: "Chart",
            children: (
              <EChartWrapper isEmpty={result === undefined || (result && result.length === 0)}>
                <ReactECharts option={option} style={{ height: "100%" }} />
              </EChartWrapper>
            ),
          },
          {
            key: "raw-data",
            label: "Raw data",
            children: (
              <EChartWrapper isEmpty={result === undefined || (result && result.length === 0)}>
                <Table
                  id="id"
                  rowKey="id"
                  sticky={{
                    offsetHeader: 0,
                  }}
                  size="small"
                  dataSource={dataSource}
                  columns={columns}
                />
              </EChartWrapper>
            ),
          },
        ]}
      />
    </ChartLayout>
  );
};
export const UserCountByInstallDateMemo = React.memo(UserCountByInstallDate);

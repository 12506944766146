import { Form, Tabs } from "antd";
import React from "react";
import { MetricPerformanceItem } from "./metric-performance-item";
import { TargetKey, useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { MetricParentTabName } from "./metric-performance-item/tab-name";

export const MetricPerformanceTabs: React.FC = () => {
  const { activeKey, setActiveKey, add, form } = useMetricPerformanceContext();

  const metricTabItemsWatch = Form.useWatch(ChartFormFieldsEnum.METRIC_TAB_ITEMS, form) ?? [];

  const onChange = (key: string) => {
    setActiveKey(key);
  };

  const onEdit = (targetKey: TargetKey, action: "add" | "remove") => {
    if (action === "add") {
      add();
    }
  };
  return (
    <Tabs
      id="metric-performance-tabs"
      onChange={onChange}
      activeKey={activeKey}
      type="editable-card"
      onEdit={onEdit}
      items={metricTabItemsWatch.map((item: any) => {
        return {
          label: <MetricParentTabName key={item.key} targetKey={item.key} />,
          key: item.key,
          children: <MetricPerformanceItem key={item.key} targetKey={item.key} />,
        };
      })}
    />
  );
};

import React from "react";
import { Card } from "antd";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { PlusOutlined } from "@ant-design/icons";

// types
import { IMemberOrder } from "@src/types/query-builder";

// components
import OrderDragItem from "./orders/order-drag-item";
import DropdownBase from "../dropdown/dropdown-base";
import { QueryOrderGroupProps } from "@src/types/query-builder-firebase";
import { convertQueryOrderToArray } from "@src/util/convert-query-order-to-array";
import { useExploreContext } from "@src/contexts/explore-context";
import { TQueryOrderArray } from "@cubejs-client/core";

type IOptions = {
  label: string;
  value: string;
};

export default function OrderGroup({
  title,
  query,
  updateQuery,
  timeDimensions = [],
  dimensions = [],
  measures = [],
}: QueryOrderGroupProps) {
  const { manualFetchDryRun } = useExploreContext();

  const [dataOrder, setDataOrder] = React.useState<IMemberOrder[]>([]);
  const queryOrder = React.useMemo(() => convertQueryOrderToArray(query.order) || [], [query.order]);

  const availableMembers = React.useMemo(() => {
    const dimensionsFiltered = dimensions.map((m) => ({ value: m.name, label: m.title }));
    const measuresFiltered = measures.map((m) => ({ value: m.name, label: m.title }));
    const timeDimensionsFiltered = timeDimensions
      .filter((m) => m.granularity)
      .map((m) => ({ value: m.dimension.name, label: m.dimension.title }));
    return [...dimensionsFiltered, ...measuresFiltered, ...timeDimensionsFiltered];
  }, [dimensions, measures, timeDimensions]);

  React.useEffect(() => {
    const newOrders = queryOrder.reduce((acc: any, currItem: any) => {
      const member = availableMembers.find((member) => member.value === currItem[0]);
      if (member) {
        acc.push({
          id: member.value,
          title: member.label,
          order: currItem[1],
        });
      }
      return acc;
    }, []);

    setDataOrder(newOrders);
  }, [queryOrder, availableMembers]);

  function onDragEnd({ source, destination }: DropResult) {
    if (!source || !destination) return;
    const [removed] = queryOrder.splice(source.index, 1);
    queryOrder.splice(destination.index, 0, removed);
    updateQuery({
      order: queryOrder,
    });
    manualFetchDryRun({
      query: {
        ...query,
        order: queryOrder,
      },
    });
  }

  function addItemOrder(option: IOptions) {
    const newOrder: TQueryOrderArray = [...queryOrder, [option.value, "asc"]];
    updateQuery({
      order: newOrder,
    });
    manualFetchDryRun({
      query: {
        ...query,
        order: newOrder,
      },
    });
  }

  function removeOrder(id: string) {
    const index = queryOrder.findIndex((order: any) => order[0] === id);
    queryOrder.splice(index, 1);
    updateQuery({
      order: queryOrder,
    });
    manualFetchDryRun({
      query: {
        ...query,
        order: queryOrder,
      },
    });
  }

  function onOrderChange(id: string, value: any) {
    const index = queryOrder.findIndex((order: any) => order[0] === id);
    queryOrder[index][1] = value;
    updateQuery({
      order: queryOrder,
    });
  }

  return (
    <Card
      size="small"
      title={title}
      className="h-full"
      extra={
        <DropdownBase
          data-testid="order-dropdown-base"
          type="dashed"
          availableMembers={availableMembers.filter((m) => !dataOrder.some((order) => order.id === m.value))}
          onClick={addItemOrder}
        >
          Add <PlusOutlined />
        </DropdownBase>
      }
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-1" type="PERSON">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {dataOrder.map(({ id, title, order }: IMemberOrder, index) => {
                // const isHidden = order === "none";
                return (
                  <OrderDragItem
                    key={id}
                    id={id}
                    title={title}
                    order={order}
                    index={index}
                    removeOrder={removeOrder}
                    onOrderChange={onOrderChange}
                    isHidden={false}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Card>
  );
}

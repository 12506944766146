import { FilterFormInstanceType } from "@src/contexts/ab-testing/ab-context";
import { FilterFormFieldsEnum } from "../enum";
import { FilterOperator } from "@src/constant/ab-testing/ad-testing-exploration.enum";

const getExplorationFilter = (member: string, operator: FilterOperator, values: any) => {
  if (values === undefined || values === null) return null;

  if (Array.isArray(values)) {
    return values.length > 0
      ? {
          member,
          operator,
          values: values,
        }
      : null;
  } else {
    return {
      member,
      operator,
      values: [values],
    };
  }
};

export type ValueOfFilterFormType = {
  productCode: string | undefined;
  productToken: string | undefined;
  abaId: string | undefined;
  variants: string[] | undefined;
  installDate: any;
  platforms: string[] | undefined;
  amaAppIds: string[] | undefined;
  onboardingDate: any;
  countryCode: any;
  mediaSource: any;
  deviceModel: any;
  deviceCategory: any;
  firstAppVersion: any;
  lastAppVersion: any;
  explorationFilters: {
    member: string;
    operator: FilterOperator;
    values: any[];
  }[];
};

export const getValueOfFiltersForm = (filterFields?: FilterFormInstanceType): ValueOfFilterFormType => {
  const {
    [FilterFormFieldsEnum.PRODUCT_CODE]: productCode,
    [FilterFormFieldsEnum.PRODUCT_TOKEN]: productToken,
    [FilterFormFieldsEnum.ABA_ID]: abaId,
    [FilterFormFieldsEnum.VARIANTS]: variants,
    [FilterFormFieldsEnum.PLATFORM]: platforms,
    [FilterFormFieldsEnum.AMA_APP_IDS]: amaAppIds,
    [FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED]: extraFields,
  } = filterFields ?? {};

  const {
    [FilterFormFieldsEnum.EXTRA_INSTALL_DATE]: installDate,
    [FilterFormFieldsEnum.EXTRA_ONBOARDING_DATE]: onboardingDate,
    [FilterFormFieldsEnum.EXTRA_COUNTRY]: countryCode,
    [FilterFormFieldsEnum.EXTRA_MEDIA_SOURCE]: mediaSource,
    [FilterFormFieldsEnum.EXTRA_DEVICE_MODAL]: deviceModel,
    [FilterFormFieldsEnum.EXTRA_DEVICE_CATEGORY]: deviceCategory,
    [FilterFormFieldsEnum.EXTRA_FIRST_APP_VERSION]: firstAppVersion,
    [FilterFormFieldsEnum.EXTRA_LAST_APP_VERSION]: lastAppVersion,
    [FilterFormFieldsEnum.INVALID_COUNTRIES]: invalidCountry,
    [FilterFormFieldsEnum.USERS_JOIN_MULTIPLE_GROUPS]: userJoinMultiPleGroups,
    [FilterFormFieldsEnum.MULTI_USER_PSEUDO_ID]: multiUserPseudoId,
    [FilterFormFieldsEnum.LATE_ONBOARDING_DATE]: lateOnboardingDate,
  } = extraFields ?? {};

  const explorationFilters = [
    getExplorationFilter("aba_id", FilterOperator.EQUALS, abaId),
    getExplorationFilter("exp_group", FilterOperator.EQUALS, variants),
    getExplorationFilter("ama_app_id", FilterOperator.EQUALS, amaAppIds),

    getExplorationFilter("install_date_tzutc", FilterOperator.BETWEEN, installDate),
    getExplorationFilter("first_signal_date", FilterOperator.BETWEEN, onboardingDate),
    getExplorationFilter("country_code", FilterOperator.EQUALS, countryCode),
    getExplorationFilter("media_source", FilterOperator.EQUALS, mediaSource),
    getExplorationFilter("device_model", FilterOperator.EQUALS, deviceModel),
    getExplorationFilter("device_category", FilterOperator.EQUALS, deviceCategory),
    getExplorationFilter("first_app_version", FilterOperator.EQUALS, firstAppVersion),
    getExplorationFilter("last_app_version", FilterOperator.EQUALS, lastAppVersion),

    getExplorationFilter("is_valid_country", FilterOperator.EQUALS, !!invalidCountry),
    getExplorationFilter("in_multi_exp_group", FilterOperator.EQUALS, !userJoinMultiPleGroups),
    getExplorationFilter("has_multi_user_pseudo_id", FilterOperator.EQUALS, !multiUserPseudoId),
    getExplorationFilter("is_late_signal", FilterOperator.EQUALS, !lateOnboardingDate),
  ].filter((f) => !!f);

  return {
    productCode,
    productToken,
    abaId,
    variants,
    installDate,
    platforms,
    amaAppIds,
    onboardingDate,
    countryCode,
    mediaSource,
    deviceModel,
    deviceCategory,
    firstAppVersion,
    lastAppVersion,
    explorationFilters,
  };
};

import { css } from "@emotion/react";
import { DIMENSIONS } from "@src/constant/ab-testing/user-count";
import { useABContext } from "@src/contexts/ab-testing/ab-context";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form, InputNumber } from "antd";
import { FormInstance } from "antd/lib/form";
import Select from "antd/lib/select";

export const MaxDayDiffFrom: React.FC<{ form: FormInstance<any> }> = ({ form }) => {
  const { updateSearchUrl } = useABContext();
  return (
    <div className="flex">
      <Form.Item
        required
        css={css`
          margin: 0 !important;
        `}
        label="Max Day diff from"
      >
        <div className="flex">
          <Form.Item name={ChartFormFieldsEnum.METRIC_DAY_DIFF_TYPE} className="mr-4">
            <Select
              style={{ width: 150 }}
              options={[
                {
                  label: "Install date",
                  value: DIMENSIONS.USER_AB_TESTING_INSTALL_DATE,
                },
                {
                  label: "Onboarding date",
                  value: DIMENSIONS.USER_AB_TESTING_FIRST_SIGNAL_DATE,
                },
              ]}
              onChange={() => {
                updateSearchUrl();
              }}
              placeholder="Day diff type"
            />
          </Form.Item>
          <Form.Item name={ChartFormFieldsEnum.METRIC_DAY_DIFF_VALUE}>
            <InputNumber
              style={{ width: 150 }}
              placeholder="Max day diff"
              min={0}
              onChange={() => {
                updateSearchUrl();
              }}
            />
          </Form.Item>
        </div>
      </Form.Item>
    </div>
  );
};

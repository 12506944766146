export const sliceFieldValue = ({
  value,
  numSlice,
  join = true,
  joinSymbol = ", ",
}: {
  value: string[];
  numSlice: number;
  join?: boolean;
  joinSymbol?: string;
}) => {
  const isExceed = value.length < numSlice;
  const getResult = (_v: string[]) => {
    return join ? _v.join(joinSymbol) : _v;
  };
  return {
    result: isExceed ? getResult(value) : getResult(value.slice(0, numSlice)),
    remaining: isExceed ? 0 : value.length - numSlice,
  };
};

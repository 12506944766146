import { Divider } from "antd";
import React from "react";
import { SignificanceLevel } from "./significance-level";
import { MetricBreakDown } from "./extra/breakdown";
import { ApplyMetric } from "./extra/apply-metric";
import { FilterInfoShow, MetricBreakdownInfoShow } from "./visualization";
import { FilterModal } from "./extra/filter-modal";
import { MetricMeasure } from "./metric-measure";
import { useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";

export const MetricSetting: React.FC<{ targetKey: string }> = ({ targetKey }) => {
  const { form } = useMetricPerformanceContext();

  const [openFilterModal, setOpenFilterModal] = React.useState(false);
  const [openBreakdownModal, setOpenBreakdownModal] = React.useState(false);

  return (
    <div>
      <div className="flex justify-between">
        <MetricMeasure targetKey={targetKey} />
        <SignificanceLevel targetKey={targetKey} />
      </div>
      <FilterInfoShow targetKey={targetKey} open={openFilterModal} setOpen={setOpenFilterModal} />
      <div className="py-2" />
      <MetricBreakdownInfoShow targetKey={targetKey} open={openBreakdownModal} setOpen={setOpenBreakdownModal} />

      <Divider />
      <div className="flex justify-between">
        <div className="w-[30%]">
          <FilterModal targetKey={targetKey} open={openFilterModal} setOpen={setOpenFilterModal} />
        </div>
        <div className="h-[20px] w-[1px] bg-gray-300" />
        <div className="w-[30%]">
          <MetricBreakDown targetKey={targetKey} open={openBreakdownModal} setOpen={setOpenBreakdownModal} />
        </div>
        <div className="h-[20px] w-[1px] bg-gray-300" />
        <div className="w-[30%]">
          <ApplyMetric />
        </div>
      </div>
    </div>
  );
};

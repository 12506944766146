import { Collapse } from "antd";
import { CollapseProps } from "antd/lib/collapse";
import { MetricSetting } from "./metric-setting";
import { UserPercentiles } from "./user-percentiles";
import { MetricTabName } from "./tab-name";
import React from "react";

type MetricPerformanceItemProps = {
  targetKey: string;
};

export const MetricPerformanceItem: React.FC<MetricPerformanceItemProps> = ({ targetKey }) => {
  const collapseItems: CollapseProps["items"] = [
    {
      key: "metric",
      label: <MetricTabName targetKey={targetKey} />,
      children: <MetricSetting targetKey={targetKey} />,
    },
    {
      key: "user-percentiles",
      label: <div>User percentiles</div>,
      children: <UserPercentiles />,
    },
  ];

  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  return <Collapse destroyInactivePanel items={collapseItems} defaultActiveKey={["metric"]} onChange={onChange} />;
};

import React from "react";

// assets
import bgLogin from "@src/assets/images/bg-login.jpeg";
import IconAmanotes from "@src/assets/images/amanotes_icon.png";

// types
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "antd";
import { LoginOutlined } from "@ant-design/icons";
function Login() {
  const { loginWithRedirect } = useAuth0();

  React.useEffect(() => {
    const url = new URL(window.location.href);
    const urlSearchParams = new URLSearchParams(url.search);
    const urlMustKeep = urlSearchParams.get("to");
    if (urlMustKeep) {
      localStorage.setItem("keep-url", url.origin + urlMustKeep);
    }
  }, []);

  return (
    <div
      style={{
        background: `url(${bgLogin}) no-repeat center center fixed`,
        backgroundSize: "cover",
      }}
    >
      <div style={{ height: "100vh", display: "flex" }}>
        <div className="bg-black/[0.6] w-full px-4 py-4 rounded flex items-center flex-col justify-center">
          <div className="flex flex-col items-center mb-4 my-auto p-4 rounded bg-white max-w-[400px] w-full">
            <div className="text-[20px] font-bold mb-4 text-center">SAT - Self Service Analytics Tools</div>
            <img src={IconAmanotes} alt="Amanotes" className="w-[100px] mx-auto" />
            <Button
              className="w-[200px] mt-4"
              type="primary"
              icon={<LoginOutlined />}
              children={"Login"}
              onClick={() => loginWithRedirect()}
            />
          </div>
          <div className="mt-auto text-white">© 2024 Amanotes. All Rights Reserved.</div>
        </div>
      </div>
    </div>
  );
}

export default Login;

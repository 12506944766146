import { Card } from "antd";
import React from "react";
import PivotGroupVisualization from "./pivot-group-visualization";
import { TableResultQueryBuilder } from "@src/pages/explore/component/firebase/chart/table-result";
import { useChartResult } from "@src/hooks/fb-explore/use-chart-result";
import { ChartResultWrapperComponent } from "@src/pages/explore/component/firebase/chart/chart-result-wrapper-component";

type ChartResultProps = {
  mode: "create" | "edit";
  layout: any;
  pivotConfig?: any;
  setPivotConfig?: React.Dispatch<any>;
};
export const ChartResultVisualization: React.FC<ChartResultProps> = ({ layout, pivotConfig, setPivotConfig }) => {
  const { page, setPage, pageSize, setPageSize, refetchResult, resultSet, isLoading } = useChartResult();

  React.useEffect(() => {
    refetchResult({ offset: (page - 1) * pageSize, limit: pageSize });
  }, []);
  return (
    <ChartResultWrapperComponent>
      <Card
        title={
          <div className="flex items-center justify-between">
            <div className="flex space-x-2">
              <span>Result</span>
              <PivotGroupVisualization layout={layout} setPivotConfig={setPivotConfig} />
            </div>
          </div>
        }
        size="small"
        className="h-full"
        classNames={{
          body: "h-[calc(100%-40px)]",
        }}
      >
        <TableResultQueryBuilder
          pivotConfig={pivotConfig}
          refetchResult={refetchResult}
          resultSet={resultSet}
          isLoading={isLoading}
          paginationControl={{
            pageSize,
            page,
            setPage,
            setPageSize,
          }}
        />
      </Card>
    </ChartResultWrapperComponent>
  );
};

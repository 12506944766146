import { ProductFilter } from "./product-filter";
import { AbaIdFilter } from "./aba-id-filter";
import { VariantFilter } from "./variant-filter";
// import { InstallDateFilter } from "./install-date-filter";
import { PlatformFilter } from "./platform-filter";
import { SubmitButton } from "./submit-button";

import { FormInstance } from "antd/lib/form";
import { FilterFormInstanceType } from "@src/contexts/ab-testing/ab-context";
import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Checkbox, Form } from "antd";
import React from "react";
import { ExtraFilterMain } from "./extra-filter";

export const ABFilterFormContent: React.FC<{ form: FormInstance<FilterFormInstanceType> }> = ({ form }) => {
  return (
    <>
      <div className="flex justify-between w-full">
        <div className="flex flex-wrap  mb-2">
          <ProductFilter form={form} />
          <AbaIdFilter form={form} />
          <PlatformFilter form={form} />
          <VariantFilter form={form} />
          {/* <InstallDateFilter /> */}
        </div>
        <div className="flex ">
          <ExtraFilterMain form={form} />
          <SubmitButton form={form} />
        </div>
      </div>
      <Form.Item name={FilterFormFieldsEnum.IS_FIRST_APPLIED} hidden valuePropName="checked">
        <Checkbox />
      </Form.Item>
    </>
  );
};

import { FilterFormFieldsEnum } from "../enum";

export const CHIP_LABELS: Record<string, string> = {
  [FilterFormFieldsEnum.INVALID_COUNTRIES]: "Invalid Countries",
  [FilterFormFieldsEnum.USERS_JOIN_MULTIPLE_GROUPS]: "Users Join Multiple Groups",
  [FilterFormFieldsEnum.MULTI_USER_PSEUDO_ID]: "Multi User Pseudo Id",
  [FilterFormFieldsEnum.LATE_ONBOARDING_DATE]: "Late Onboarding Date",

  [FilterFormFieldsEnum.EXTRA_INSTALL_DATE]: "Install Date",
  [FilterFormFieldsEnum.EXTRA_ONBOARDING_DATE]: "Onboarding Date",

  [FilterFormFieldsEnum.EXTRA_COUNTRY]: "Country",
  [FilterFormFieldsEnum.EXTRA_MEDIA_SOURCE]: "Media Source",
  [FilterFormFieldsEnum.EXTRA_DEVICE_CATEGORY]: "Device Category",
  [FilterFormFieldsEnum.EXTRA_DEVICE_MODAL]: "Device Modal",
  [FilterFormFieldsEnum.EXTRA_FIRST_APP_VERSION]: "First App Version",
  [FilterFormFieldsEnum.EXTRA_LAST_APP_VERSION]: "Last App Version",
};

export const CHIP_RELATIONS: Record<string, "with" | "between" | "is"> = {
  [FilterFormFieldsEnum.INVALID_COUNTRIES]: "with",
  [FilterFormFieldsEnum.USERS_JOIN_MULTIPLE_GROUPS]: "with",
  [FilterFormFieldsEnum.MULTI_USER_PSEUDO_ID]: "with",
  [FilterFormFieldsEnum.LATE_ONBOARDING_DATE]: "with",

  [FilterFormFieldsEnum.EXTRA_INSTALL_DATE]: "between",
  [FilterFormFieldsEnum.EXTRA_ONBOARDING_DATE]: "between",

  [FilterFormFieldsEnum.EXTRA_COUNTRY]: "is",
  [FilterFormFieldsEnum.EXTRA_MEDIA_SOURCE]: "is",
  [FilterFormFieldsEnum.EXTRA_DEVICE_CATEGORY]: "is",
  [FilterFormFieldsEnum.EXTRA_DEVICE_MODAL]: "is",
  [FilterFormFieldsEnum.EXTRA_FIRST_APP_VERSION]: "is",
  [FilterFormFieldsEnum.EXTRA_LAST_APP_VERSION]: "is",
};

import { FormInstance } from "antd/lib/form/Form";
import React from "react";
import { UserCountByInstallDateMemo } from "./user-count-by-install-date";
import { UserCountByMediaSourceMemo } from "./user-count-by-media-source";
import Collapse from "antd/lib/collapse/Collapse";
import { ChartWrapperContextProvider } from "@src/contexts/ab-testing/chart-wrapper-context";
import { ChartFormFieldsEnum, UserDistributionTabsKeyEnum } from "@src/pages/ab-test-explore/enum";
import { UserCountByCountryCodeMemo } from "./user-count-by-country-code";
import { UserCountByGenderMemo } from "./user-count-by-gender";
import { UserCountByAgeMemo } from "./user-count-by-age";
import { CubeProvider } from "@cubejs-client/react";
import { Form } from "antd";
import { Maybe } from "@src/types";
import cubejs from "@cubejs-client/core";
import { config } from "@src/config";
import { LocationTabsView, useUpdateTabsViewParams } from "@src/hooks/ab-testing/use-update-tabs-view-params";
import { ChartAction } from "../action";
import { MessageDescription } from "@src/constant/explore/message-description";
import { TooltipChartLayout } from "../../layout/tooltip-chart-layout";
import { FilterFormInstanceType } from "@src/contexts/ab-testing/ab-context";

export type UserCountItemProps = {
  form: FormInstance;
  chartKey: UserDistributionTabsKeyEnum;
};
const AbTestUserDistribution: React.FC<{ form: FormInstance }> = ({ form }) => {
  const { items, onChange } = useUpdateTabsViewParams<string | string[]>({
    location: LocationTabsView.USER_DISTRIBUTION,
  });
  const filtersFieldWatch = Form.useWatch(ChartFormFieldsEnum.FILTER_FIELDS, form) as Maybe<FilterFormInstanceType>;

  const CUBE_TOKEN = filtersFieldWatch?.product_token ?? "";
  const cubejsApi = cubejs(CUBE_TOKEN, { apiUrl: config.CUBE_URL });

  return (
    <CubeProvider cubeApi={cubejsApi}>
      <ChartWrapperContextProvider>
        <Collapse
          className="ab-testing-collapse"
          activeKey={items}
          onChange={onChange}
          items={[
            {
              key: UserDistributionTabsKeyEnum.INSTALL_DATE,
              label: (
                <TooltipChartLayout label="Install Date" content={MessageDescription.USER_DISTRIBUTION.INSTALL_DATE} />
              ),
              children: <UserCountByInstallDateMemo form={form} chartKey={UserDistributionTabsKeyEnum.INSTALL_DATE} />,
              extra: (
                <ChartAction tabKey={UserDistributionTabsKeyEnum.INSTALL_DATE} tabName="Install Date" form={form} />
              ),
            },
            {
              key: UserDistributionTabsKeyEnum.MEDIA_SOURCE,
              label: (
                <TooltipChartLayout label="Media Source" content={MessageDescription.USER_DISTRIBUTION.MEDIA_SOURCE} />
              ),
              children: <UserCountByMediaSourceMemo form={form} chartKey={UserDistributionTabsKeyEnum.MEDIA_SOURCE} />,
              extra: (
                <ChartAction tabKey={UserDistributionTabsKeyEnum.MEDIA_SOURCE} tabName="Media Source" form={form} />
              ),
            },
            {
              key: UserDistributionTabsKeyEnum.COUNTRY_CODE,
              label: <TooltipChartLayout label="Country" content={MessageDescription.USER_DISTRIBUTION.COUNTRY} />,
              children: <UserCountByCountryCodeMemo form={form} chartKey={UserDistributionTabsKeyEnum.COUNTRY_CODE} />,
              extra: <ChartAction tabKey={UserDistributionTabsKeyEnum.COUNTRY_CODE} tabName="Country" form={form} />,
            },
            // TODO: convert to data access api later
            // {
            //   key: UserDistributionTabsKeyEnum.GENDER,
            //   label: <TooltipChartLayout label="Gender" content={MessageDescription.USER_DISTRIBUTION.GENDER} />,
            //   children: <UserCountByGenderMemo form={form} chartKey={UserDistributionTabsKeyEnum.GENDER} />,
            //   extra: <ChartAction tabKey={UserDistributionTabsKeyEnum.GENDER} tabName="Gender" form={form} />,
            // },
            // {
            //   key: UserDistributionTabsKeyEnum.AGE,
            //   label: <TooltipChartLayout label="Age" content={MessageDescription.USER_DISTRIBUTION.AGE} />,
            //   children: <UserCountByAgeMemo form={form} chartKey={UserDistributionTabsKeyEnum.AGE} />,
            //   extra: <ChartAction tabKey={UserDistributionTabsKeyEnum.AGE} tabName="Age" form={form} />,
            // },
          ]}
        />
      </ChartWrapperContextProvider>
    </CubeProvider>
  );
};

export const AbTestUserDistributionMemo = React.memo(AbTestUserDistribution);

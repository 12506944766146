import { Form } from "antd";
import { useCustom } from "@refinedev/core";
import { config } from "@src/config";
import { CubeProvider } from "@cubejs-client/react";
import cubejs from "@cubejs-client/core";

import { ABFilterFormContent } from "./form";
import { FormInstance } from "antd/lib/form";
import { ChartFormFieldsEnum, FilterFormFieldsEnum } from "../../enum";
import React from "react";
import { ChartFormInstanceType, FilterFormInstanceType } from "@src/contexts/ab-testing/ab-context";
import { FORM_NAME } from "@src/constant/ab-testing/user-count";
import { getGroupFieldValue } from "@src/util/ab-testing/params";
import { FilterExplorationLayout } from "../layout/filter-exploration-layout";
import { ALL_EXTRA_FIELDS_FILTER } from "../../contanst/filter-fields";
import { convertDateRangeToDayJS, convertDateRangeToString } from "@src/util/time-form";

type FilterExplorationProps = {
  form: FormInstance<FilterFormInstanceType>;
  chartFormInstance: FormInstance<ChartFormInstanceType>;
};
export const FilterExploration: React.FC<FilterExplorationProps> = ({ form, chartFormInstance }) => {
  const productCodeWatch = Form.useWatch(FilterFormFieldsEnum.PRODUCT_CODE, form);

  const { data, isLoading: isLoadingGenerateToken } = useCustom({
    url: `${config.BACKEND_URL}/product/${productCodeWatch}/generate-cube-token`,
    method: "get",
    queryOptions: {
      retry: 0,
      enabled: !!productCodeWatch,
      onSuccess: (data) => {
        const token = data?.data?.jwt_token;
        if (token) {
          form.setFieldValue(FilterFormFieldsEnum.PRODUCT_TOKEN, token);
        }
      },
    },
    errorNotification: () => {
      return {
        type: "error",
        message: "There was an error fetching data",
        description: "Error",
      };
    },
  });

  const CUBE_TOKEN = data?.data?.jwt_token;
  const cubejsApi = cubejs(CUBE_TOKEN, { apiUrl: config.CUBE_URL });

  const handleFinish = (values: any) => {
    const _values: Record<string, any> = {};

    [
      FilterFormFieldsEnum.PRODUCT_CODE,
      FilterFormFieldsEnum.PRODUCT_TOKEN,
      FilterFormFieldsEnum.ABA_ID,
      FilterFormFieldsEnum.AMA_APP_IDS,
      FilterFormFieldsEnum.PLATFORM,
      FilterFormFieldsEnum.VARIANTS,
      FilterFormFieldsEnum.EXTRA_INSTALL_DATE,
      FilterFormFieldsEnum.EXTRA_ONBOARDING_DATE,
      FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED,
    ].forEach((field) => {
      if ([FilterFormFieldsEnum.EXTRA_INSTALL_DATE, FilterFormFieldsEnum.EXTRA_ONBOARDING_DATE].includes(field)) {
        _values[field] = values?.[FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED]?.[field]
          ? convertDateRangeToString(values?.[FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED]?.[field])
          : undefined;
      } else {
        _values[field] = values[field];
      }
    });
    chartFormInstance.setFieldsValue({
      [ChartFormFieldsEnum.FILTER_FIELDS]: _values,
    });
  };

  const getInitValue = () => {
    const filterForm = getGroupFieldValue("filterForm") ?? {};
    const hasConfigExited = Object.keys(filterForm).length > 0;

    const filterValuesConfirmed: Record<string, any> = {};
    ALL_EXTRA_FIELDS_FILTER.forEach((field) => {
      const value = filterForm[field];

      if (value) {
        filterValuesConfirmed[field] = value;
        // if ([FilterFormFieldsEnum.EXTRA_INSTALL_DATE, FilterFormFieldsEnum.EXTRA_ONBOARDING_DATE].includes(field)) {
        //   filterValuesConfirmed[field] = convertDateRangeToDayJS(value);
        // } else {
        //   filterValuesConfirmed[field] = value;
        // }
      }
    });

    const initFilterOutUserField = {
      [FilterFormFieldsEnum.INVALID_COUNTRIES]: true,
      [FilterFormFieldsEnum.MULTI_USER_PSEUDO_ID]: true,
      [FilterFormFieldsEnum.USERS_JOIN_MULTIPLE_GROUPS]: true,
      [FilterFormFieldsEnum.LATE_ONBOARDING_DATE]: true,
    };

    return {
      ...filterForm,
      ...(!hasConfigExited
        ? {
            ...initFilterOutUserField,
            [FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED]: initFilterOutUserField,
          }
        : {
            [FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED]: filterValuesConfirmed,
          }),
      [FilterFormFieldsEnum.IS_SHOW_CHIP]: true,
      [FilterFormFieldsEnum.EXTRA_INSTALL_DATE]: convertDateRangeToDayJS(
        filterForm?.[FilterFormFieldsEnum.EXTRA_INSTALL_DATE],
      ),
      [FilterFormFieldsEnum.EXTRA_ONBOARDING_DATE]: convertDateRangeToDayJS(
        filterForm?.[FilterFormFieldsEnum.EXTRA_ONBOARDING_DATE],
      ),
      [FilterFormFieldsEnum.IS_ABA_ID_CHANGED]: false,
    };
  };
  return (
    <FilterExplorationLayout form={form} isLoadingGenerateToken={isLoadingGenerateToken}>
      <CubeProvider cubeApi={cubejsApi}>
        <Form
          name={FORM_NAME.FILTER_FORM}
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          initialValues={getInitValue()}
        >
          <ABFilterFormContent form={form} />
          <Form.Item name={FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED} hidden />
        </Form>
      </CubeProvider>
    </FilterExplorationLayout>
  );
};

import { useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form, Input, Typography } from "antd";

export const MetricTabName: React.FC<{ targetKey: string }> = ({ targetKey }) => {
  const { form, handleUpdateMetricTabItems } = useMetricPerformanceContext();
  const metricTabName = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_TAB_NAME], form);

  return (
    <div className="flex justify-start">
      <div
        className=""
        onClick={(e) => {
          e.stopPropagation();
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.stopPropagation();
          }
        }}
      >
        <div className="flex">
          <Typography.Paragraph
            className="m-0 "
            editable={{
              onChange: (value) => {
                form.setFieldValue([targetKey, ChartFormFieldsEnum.METRIC_TAB_NAME], value);
                handleUpdateMetricTabItems(targetKey, ChartFormFieldsEnum.METRIC_TAB_NAME);
              },
            }}
          >
            {metricTabName}
          </Typography.Paragraph>
        </div>
        <Form.Item name={[targetKey, ChartFormFieldsEnum.METRIC_TAB_NAME]} hidden>
          <Input />
        </Form.Item>
      </div>
    </div>
  );
};

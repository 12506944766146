import { Card } from "antd";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

interface QueryBuilderChartLayoutProps {
  autoSaveId?: string;
  queryBuilderComponent: React.ReactNode;
  showResultComponent: React.ReactNode;
}
export const QueryBuilderChartLayout: React.FC<QueryBuilderChartLayoutProps> = ({
  autoSaveId,
  queryBuilderComponent,
  showResultComponent,
}) => {
  return (
    <PanelGroup autoSaveId={autoSaveId} direction="horizontal" className="flex w-full space-x-2">
      <Panel defaultSize={30} minSize={30}>
        <Card size="small" title={"Query Builder"} className="h-[calc(100vh-200px)] overflow-y-scroll p-0 ">
          {queryBuilderComponent}
        </Card>
      </Panel>
      <PanelResizeHandle
      // tagName="div"
      // style={{
      //   flex: "0 0 0.25rem",
      //   position: "relative",
      //   outline: "none",
      //   backgroundColor: "transparent",
      // }}
      >
        {/* <div className="hover:bg-blue-500 absolute top-0 bottom-0 left-0 right-0 rounded-[4px] duration-300" /> */}
      </PanelResizeHandle>
      <Panel minSize={30}>
        <div className="h-[calc(100vh-200px)] overflow-y-hidden">{showResultComponent}</div>
      </Panel>
    </PanelGroup>
  );
};

import { useABContext } from "@src/contexts/ab-testing/ab-context";
import { useManageTabsContext } from "@src/contexts/ab-testing/manage-tabs-context";
import { TabsKeyEnum, TabsViewParamsEnum } from "@src/pages/ab-test-explore/enum";
import React from "react";

export enum LocationTabsView {
  CHART_TOP = "chart_top",
  USER_DISTRIBUTION = "user_distribution",
  ENGAGEMENT = "engagement",
  EVENT_BREAKDOWN = "event_breakdown",
  TIME_IN_APP = "time_in_app",
  MONETIZATION = "monetization",
  ADS = "ads",
  IAPS = "iaps",
}

export const useUpdateTabsViewParams = <T>({ location }: { location: LocationTabsView }) => {
  const { updateSearchUrl } = useABContext();
  const {
    mainTabsActive,
    setMainTabsActive,
    engagementTabsActive,
    setEngagementTabsActive,
    distributionItemsPanelActive,
    setDistributionItemsPanelActive,
    eventBreakdownItemsPanelActive,
    setEventBreakdownItemsPanelActive,
    timeAppItemsPanelActive,
    setTimeAppItemsPanelActive,
    monetizationTabsActive,
    setMonetizationTabsActive,
    adsItemsPanelActive,
    setAdsItemsPanelActive,
    iapsItemsPanelActive,
    setIapsItemsPanelActive,
  } = useManageTabsContext();

  let items: any;
  let setItems: React.Dispatch<React.SetStateAction<any>>;

  switch (location) {
    case LocationTabsView.CHART_TOP:
      items = mainTabsActive;
      setItems = setMainTabsActive;
      break;
    case LocationTabsView.USER_DISTRIBUTION:
      items = distributionItemsPanelActive;
      setItems = setDistributionItemsPanelActive;
      break;
    case LocationTabsView.ENGAGEMENT:
      items = engagementTabsActive;
      setItems = setEngagementTabsActive;
      break;
    case LocationTabsView.EVENT_BREAKDOWN:
      items = eventBreakdownItemsPanelActive;
      setItems = setEventBreakdownItemsPanelActive;
      break;
    case LocationTabsView.TIME_IN_APP:
      items = timeAppItemsPanelActive;
      setItems = setTimeAppItemsPanelActive;
      break;
    case LocationTabsView.MONETIZATION:
      items = monetizationTabsActive;
      setItems = setMonetizationTabsActive;
      break;
    case LocationTabsView.ADS:
      items = adsItemsPanelActive;
      setItems = setAdsItemsPanelActive;
      break;
    case LocationTabsView.IAPS:
      items = iapsItemsPanelActive;
      setItems = setIapsItemsPanelActive;
      break;
    default:
      items = mainTabsActive;
      setItems = setMainTabsActive;
      break;
  }

  const onChange = (key: T) => {
    setItems(key);
    switch (location) {
      case LocationTabsView.CHART_TOP: {
        const baseTabsConfig = [
          {
            key: TabsViewParamsEnum.MAIN_TABS,
            value: key,
          },
          {
            key: TabsViewParamsEnum.SUB_TABS,
            value: engagementTabsActive,
          },
        ];
        if (key === TabsKeyEnum.USER_DISTRIBUTION) {
          updateSearchUrl([
            ...baseTabsConfig,
            {
              key: TabsViewParamsEnum.COLLAPSE_ITEMS,
              value: distributionItemsPanelActive,
            },
          ]);
        } else {
          updateSearchUrl([
            ...baseTabsConfig,
            {
              key: TabsViewParamsEnum.COLLAPSE_ITEMS,
              value:
                engagementTabsActive === TabsKeyEnum.TIME_IN_APP
                  ? timeAppItemsPanelActive
                  : eventBreakdownItemsPanelActive,
            },
          ]);
        }
        break;
      }
      case LocationTabsView.ENGAGEMENT: {
        updateSearchUrl([
          {
            key: TabsViewParamsEnum.SUB_TABS,
            value: key,
          },
          {
            key: TabsViewParamsEnum.COLLAPSE_ITEMS,
            value: key === TabsKeyEnum.TIME_IN_APP ? timeAppItemsPanelActive : eventBreakdownItemsPanelActive,
          },
        ]);
        break;
      }
      case LocationTabsView.MONETIZATION: {
        updateSearchUrl([
          {
            key: TabsViewParamsEnum.SUB_TABS,
            value: key,
          },
          {
            key: TabsViewParamsEnum.COLLAPSE_ITEMS,
            value: key === TabsKeyEnum.ADS ? adsItemsPanelActive : iapsItemsPanelActive,
          },
        ]);
        break;
      }
      case LocationTabsView.USER_DISTRIBUTION:
      case LocationTabsView.TIME_IN_APP:
      case LocationTabsView.EVENT_BREAKDOWN:
      case LocationTabsView.ADS:
      case LocationTabsView.IAPS:
        updateSearchUrl({
          key: TabsViewParamsEnum.COLLAPSE_ITEMS,
          value: key,
        });
        break;
    }
  };

  return {
    items,
    onChange,
  };
};

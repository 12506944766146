/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { ChartFormFieldsEnum, FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import React, { useState } from "react";
import { useABContext } from "@src/contexts/ab-testing/ab-context";
import { getGroupFieldValue } from "@src/util/ab-testing/params";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

interface SubmitButtonProps {
  form: FormInstance<any>;
}
const paramsConfig = getGroupFieldValue("filterForm");
export const SubmitButton: React.FC<SubmitButtonProps> = ({ form }) => {
  const navigate = useNavigate();
  const { updateSearchUrl } = useABContext();

  const [currentFields, setCurrentFields] = useState<any>({});

  const productWatch = Form.useWatch(FilterFormFieldsEnum.PRODUCT_CODE, form);
  const productToken = Form.useWatch(FilterFormFieldsEnum.PRODUCT_TOKEN, form);
  const abaIdWatch = Form.useWatch(FilterFormFieldsEnum.ABA_ID, form);
  const variantWatch = Form.useWatch(FilterFormFieldsEnum.VARIANTS, form);
  const platformWatch = Form.useWatch(FilterFormFieldsEnum.PLATFORM, form);

  const extraFilterConfirmedWatch = Form.useWatch(FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED, form);

  const isHasPlatform = Array.isArray(platformWatch) && platformWatch?.length > 0;
  const isUserAppliedFilter = Form.useWatch(FilterFormFieldsEnum.IS_FIRST_APPLIED, form);

  const enoughFieldsRequired = !!productWatch && !!productToken && abaIdWatch && isHasPlatform;

  const isFieldChange =
    JSON.stringify(currentFields) !==
    JSON.stringify({
      [FilterFormFieldsEnum.PRODUCT_CODE]: productWatch,
      [FilterFormFieldsEnum.ABA_ID]: abaIdWatch,
      [FilterFormFieldsEnum.VARIANTS]: variantWatch,
      [FilterFormFieldsEnum.PLATFORM]: platformWatch,
      [FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED]: extraFilterConfirmedWatch,
    });
  console.log({ enoughFieldsRequired, isFieldChange, currentFields });

  const isDisable = !enoughFieldsRequired || !isFieldChange;

  const getInitValuesChart = () => {
    const chartForm = getGroupFieldValue("chartForm") ?? {};
    return {
      ...chartForm,
      [ChartFormFieldsEnum.CUSTOM_TIME_RANGE]: chartForm?.[ChartFormFieldsEnum.CUSTOM_TIME_RANGE]?.map((item: string) =>
        dayjs(item),
      ),
    };
  };

  const handleClick = () => {
    form.validateFields().then(() => {
      updateSearchUrl(
        undefined,
        !isUserAppliedFilter
          ? {
              chartForm: getInitValuesChart(),
            }
          : undefined,
        navigate,
      );
      form.setFieldValue(FilterFormFieldsEnum.IS_FIRST_APPLIED, true);
      form.submit();
      setCurrentFields({
        [FilterFormFieldsEnum.PRODUCT_CODE]: productWatch,
        [FilterFormFieldsEnum.ABA_ID]: abaIdWatch,
        [FilterFormFieldsEnum.VARIANTS]: variantWatch,
        [FilterFormFieldsEnum.PLATFORM]: platformWatch,
        [FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED]: extraFilterConfirmedWatch,
      });
    });
  };
  const [isAlreadySetFirst, setIsAlreadySetFirst] = useState(!paramsConfig);

  React.useEffect(() => {
    if (isDisable) return;
    if (isUserAppliedFilter) return;
    if (isAlreadySetFirst) return;
    console.log("auto submit");
    handleClick();
    setIsAlreadySetFirst(true);
  }, [form, isDisable, isUserAppliedFilter, isAlreadySetFirst]);

  return (
    <div className="pt-7 mb-2">
      <Form.Item className="ml-2">
        <Button type="primary" disabled={isDisable} onClick={handleClick}>
          Apply
        </Button>
      </Form.Item>
    </div>
  );
};

import { ExclamationCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Card, Checkbox, Form, Tooltip } from "antd";
import { FormInstance } from "antd/lib/form";

export const FilterOutUser: React.FC<{ form: FormInstance }> = ({ form }) => {
  return (
    <Card
      style={{
        boxShadow: "0 0 0 0",
      }}
      className="mb-8"
      title={
        <div className="flex">
          Filter out users{" "}
          <Tooltip className="ml-4" title="Tooltip">
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      }
    >
      <div className="flex flex-wrap justify-around w-full">
        <Form.Item
          name={FilterFormFieldsEnum.INVALID_COUNTRIES}
          label={
            <span>
              Invalid countries{" "}
              <Tooltip title="Tooltip">
                <ExclamationCircleOutlined />
              </Tooltip>
            </span>
          }
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name={FilterFormFieldsEnum.USERS_JOIN_MULTIPLE_GROUPS}
          label={
            <span>
              Users join multiple groups{" "}
              <Tooltip title="Tooltip">
                <ExclamationCircleOutlined />
              </Tooltip>
            </span>
          }
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name={FilterFormFieldsEnum.MULTI_USER_PSEUDO_ID}
          label={
            <span>
              Multi user pseudo_id{" "}
              <Tooltip title="Tooltip">
                <ExclamationCircleOutlined />
              </Tooltip>
            </span>
          }
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name={FilterFormFieldsEnum.LATE_ONBOARDING_DATE}
          label={
            <span>
              Late onboarding date{" "}
              <Tooltip title="Tooltip">
                <ExclamationCircleOutlined />
              </Tooltip>
            </span>
          }
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      </div>
    </Card>
  );
};

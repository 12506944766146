import React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { Row, Col, Divider, Checkbox, RadioChangeEvent } from "antd";

// types
import { IChart } from "@src/types/chart";

// context

// helpers
import { movePivotOrder } from "@src/helpers/move-pivot-order";

// components
import DroppableArea from "./droppable-area";

// types
import { IPivotConfig } from "@src/types/query-builder";
import { useExploreContext } from "@src/contexts/explore-context";
import { config } from "@src/config";

type IProps = {
  layout: IChart;
};

export const AxesPivot = React.forwardRef(({ layout }: IProps, ref: any) => {
  // refs
  const pivotConfig: IPivotConfig = layout.vizState.pivotConfig;
  // state
  const [pivotOver, setPivotOver] = React.useState<IPivotConfig | null>(null);

  // refs
  const keepDefaultPivotOrder = React.useRef<IPivotConfig | null>(null);
  const { updatePivotConfig } = useExploreContext();
  React.useImperativeHandle(
    ref,
    () => {
      return {
        resetPivot: () => {
          setPivotOver(keepDefaultPivotOrder.current);
        },
        updatePivot: () => {
          if (!pivotOver) return;
          updatePivotConfig.update({
            ...pivotOver,
            limit: config.DEFAULT_PAGE_SIZE,
          });
        },
        getNewPivot: () => {
          return pivotOver;
        },
      };
    },
    [pivotOver, updatePivotConfig],
  );

  React.useEffect(() => {
    setPivotOver(pivotConfig);
    keepDefaultPivotOrder.current = pivotConfig;
  }, [pivotConfig]);

  const onDragEnd = ({ source, destination }: any) => {
    if (!destination) {
      return;
    }
    const sourceIndex = source.index;
    const sourceAxis = source.droppableId;
    const destinationIndex = destination.index;
    const destinationAxis = destination.droppableId;

    const nextPivotOver = movePivotOrder({
      pivotOver,
      sourceAxis,
      sourceIndex,
      destinationAxis,
      destinationIndex,
    });
    setPivotOver(nextPivotOver);
  };

  function checkFillMissingDate(event: RadioChangeEvent) {
    setPivotOver((prevState) => ({
      ...prevState,
      fillMissingDates: event.target.checked,
    }));
  }

  return (
    <>
      {pivotOver && (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <Row>
              <Col span={12} style={{ minWidth: 160 }}>
                <DroppableArea pivotConfig={pivotOver} axis="x" />
              </Col>

              <Col span={12} style={{ minWidth: 160 }}>
                <DroppableArea pivotConfig={pivotOver} axis="y" />
              </Col>
            </Row>
          </DragDropContext>

          <Divider style={{ margin: 0 }} />
          <div style={{ padding: "8px" }}>
            <Checkbox defaultChecked={pivotOver.fillMissingDates} onChange={checkFillMissingDate}>
              Fill Missing Dates
            </Checkbox>
          </div>
        </>
      )}
    </>
  );
});

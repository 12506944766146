import { css } from "@emotion/react";
import React from "react";

type ChartResultWrapperProps = {
  children: React.ReactNode;
};
export const ChartResultWrapperComponent: React.FC<ChartResultWrapperProps> = ({ children }) => {
  return (
    <div
      className="h-full "
      css={css`
        .ant-card-body {
          height: 100%;
        }
        .ant-pagination {
          position: sticky;
          bottom: 0px;
          background-color: white;
        }
        .ant-table-tbody-virtual-scrollbar-vertical {
          width: 4px !important;
          & > div {
            background-color: #0000005e !important;
          }
        }
        .ant-table-tbody-virtual-scrollbar-horizontal {
          display: none;
        }
      `}
    >
      {children}
    </div>
  );
};

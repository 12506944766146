import { FilterOutlined } from "@ant-design/icons";
import { QueryBuilderAntD } from "@react-querybuilder/antd";
import { Form, Input, Modal, Select, TreeDataNode, TreeSelect } from "antd";
import React from "react";
import QueryBuilder, { FieldSelectorProps, ValueEditorProps, ValueSelectorProps } from "react-querybuilder";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { abTestingCubeOptions } from "../../../mock/ab-testing-cube-options";
import { SelectWithSpinAndCustomMaxTag } from "@src/components/select/SelectWithCustomMaxTag";

export const mappingTreeData = (cubeOptions: any[]): TreeDataNode[] => {
  return cubeOptions.map((cubeOption) => {
    if (cubeOption.children) {
      return {
        title: cubeOption.title,
        key: cubeOption.key,
        value: cubeOption.value,
        children: mappingTreeData(cubeOption.children),
      };
    }
    return {
      title: cubeOption.title,
      key: cubeOption.key,
      value: cubeOption.value,
    };
  });
};
type FilterModalProps = {
  targetKey: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const _FilterModal: React.FC<FilterModalProps> = ({ targetKey, open, setOpen }) => {
  const { form, handleUpdateMetricTabItems } = useMetricPerformanceContext();

  const [newQuery, setNewQuery] = React.useState<any>(
    form.getFieldValue([targetKey, ChartFormFieldsEnum.METRIC_FILTER]),
  );

  const filterWatch = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_FILTER], form);

  const fieldSelector = React.useCallback((props: FieldSelectorProps) => {
    const { handleOnChange, rule } = props;
    const onChange = (newValue: string, opts: React.ReactNode[]) => {
      if (!opts[0]) return;
      handleOnChange(newValue);
    };

    const treeDataMapping = mappingTreeData(abTestingCubeOptions);
    return (
      <TreeSelect
        onChange={onChange}
        treeData={treeDataMapping}
        style={{ width: 350 }}
        value={rule.field}
        treeLine={{
          showLeafIcon: false,
        }}
      />
    );
  }, []);

  const valueEditor = React.useCallback((props: ValueEditorProps) => {
    const { handleOnChange, value, operator } = props;

    if (operator === "=") {
      return (
        <Input
          value={value}
          style={{ width: 200 }}
          placeholder="Please input value"
          onChange={(v) => {
            handleOnChange(v.target.value);
          }}
        />
      );
    }
    return (
      <SelectWithSpinAndCustomMaxTag
        {...(Boolean(value) && { value: value })}
        style={{ width: 200 }}
        mode="tags"
        placeholder="Please input value"
        onChange={(_value) => handleOnChange(_value)}
      />
    );
  }, []);

  const valueSelector = React.useCallback((props: ValueSelectorProps) => {
    const { handleOnChange, value } = props;
    return (
      <Select
        defaultValue={"="}
        value={value}
        onChange={handleOnChange}
        style={{
          width: 100,
        }}
        options={[
          { value: "=", label: "=" },
          { value: "between", label: "between" },
        ]}
      />
    );
  }, []);
  const handleOk = () => {
    form.setFieldValue([targetKey, ChartFormFieldsEnum.METRIC_FILTER], newQuery);
    handleUpdateMetricTabItems(targetKey, ChartFormFieldsEnum.METRIC_FILTER);
    setOpen(false);
  };
  return (
    <div className="flex justify-center items-center cursor-pointer">
      <div
        className="hover:text-blue-600 text-gray-500"
        onClick={() => {
          setOpen(true);
        }}
      >
        <FilterOutlined className="mr-1" />
        Filter ({filterWatch?.rules?.length || 0})
      </div>
      <Modal open={open} onCancel={() => setOpen(false)} onOk={handleOk} width={800} title="Metric filter" centered>
        <QueryBuilderAntD>
          <QueryBuilder
            key={targetKey}
            query={newQuery}
            fields={abTestingCubeOptions}
            controlElements={{
              fieldSelector: fieldSelector,
              valueEditor: valueEditor,
              valueSelector: valueSelector,
              combinatorSelector: () => {
                return (
                  <Select
                    style={{ width: 80 }}
                    defaultValue={"and"}
                    disabled
                    options={[{ name: "and", label: "AND", value: "and" }]}
                  />
                );
              },
            }}
            controlClassnames={{
              addGroup: "hidden",
            }}
            // getOperators={() => {
            //   return [
            //     { name: "=", value: "=", label: "=" },
            //     { name: "between", value: "between", label: "between" },
            //   ];
            // }}
            onQueryChange={(query: any) => {
              setNewQuery(query);
            }}
          />
        </QueryBuilderAntD>
      </Modal>
      <Form.Item hidden name={[targetKey, ChartFormFieldsEnum.METRIC_FILTER]} />
    </div>
  );
};

export const FilterModal = React.memo(_FilterModal);

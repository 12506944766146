import { Card, Form, FormInstance, Input, Tabs } from "antd";
import { ChartFormFieldsEnum, TabsKeyEnum } from "../../enum";
import { ABTestingEngagementMemo } from "./engagement";
import { LocationTabsView, useUpdateTabsViewParams } from "@src/hooks/ab-testing/use-update-tabs-view-params";
import React from "react";
import { AbTestUserDistributionMemo } from "./user-distribution";
import { css } from "@emotion/react";
import { DIMENSIONS, FORM_NAME } from "@src/constant/ab-testing/user-count";
import { getGroupFieldValue } from "@src/util/ab-testing/params";
import dayjs from "dayjs";
import { MetricPerformance } from "./metric-performance";

const ShowChartResult: React.FC<{ form: FormInstance }> = ({ form }) => {
  const { items, onChange } = useUpdateTabsViewParams<string>({
    location: LocationTabsView.CHART_TOP,
  });
  const getInitValuesChart = () => {
    const formValues = getGroupFieldValue("chartForm") ?? {};
    const metricTabItems = formValues?.[ChartFormFieldsEnum.METRIC_TAB_ITEMS] ?? [];
    const metricPerformanceDetail = metricTabItems.reduce((acc: Record<string, any>, item: Record<string, any>) => {
      acc[item.key] = item;
      return acc;
    }, {});

    const mappingWithDefault = [
      {
        key: ChartFormFieldsEnum.CUSTOM_TIME_RANGE,
        value: formValues?.[ChartFormFieldsEnum.CUSTOM_TIME_RANGE]?.map((item: string) => dayjs(item)),
      },
      {
        key: ChartFormFieldsEnum.METRIC_TIME_RANGE,
        value: formValues?.[ChartFormFieldsEnum.METRIC_TIME_RANGE]?.map((item: string) => dayjs(item)),
      },
      {
        key: ChartFormFieldsEnum.METRIC_DAY_DIFF_TYPE,
        value: formValues?.[ChartFormFieldsEnum.METRIC_DAY_DIFF_TYPE] ?? DIMENSIONS.USER_AB_TESTING_INSTALL_DATE,
      },
      {
        key: ChartFormFieldsEnum.METRIC_DAY_DIFF_VALUE,
        value: formValues?.[ChartFormFieldsEnum.METRIC_DAY_DIFF_VALUE] ?? 0,
      },
    ];

    const dataWithDefault: Record<string, any> = {};

    mappingWithDefault.forEach(({ key, value }) => {
      dataWithDefault[key] = value;
    });

    return {
      ...formValues,
      ...metricPerformanceDetail,
      ...dataWithDefault,
    };
  };

  return (
    <Form name={FORM_NAME.CHART_FORM} form={form} initialValues={getInitValuesChart()}>
      <Card
        css={css`
          .ant-tabs-tab-active {
            background-color: white !important;
          }
        `}
      >
        <Tabs
          type="card"
          activeKey={items}
          onChange={onChange}
          items={[
            {
              label: "User distribution",
              key: TabsKeyEnum.USER_DISTRIBUTION,
              children: <AbTestUserDistributionMemo form={form} />,
            },
            // {
            //   label: "Engagement",
            //   key: TabsKeyEnum.ENGAGEMENT,
            //   children: <ABTestingEngagementMemo form={form} />,
            // },
            {
              label: "Metric Performance",
              key: TabsKeyEnum.METRIC_PERFORMANCE,
              children: <MetricPerformance form={form} />,
            },
            // {
            //   label: "Monetization",
            //   key: TabsKeyEnum.MONETIZATION,
            //   children: <ABTestingMonetizationMemo form={form} />,
            // },
          ]}
        />
      </Card>

      <Form.Item name={ChartFormFieldsEnum.FILTER_FIELDS} hidden>
        <Input />
      </Form.Item>
    </Form>
  );
};
export const ShowChartResultMemo = React.memo(ShowChartResult);

import { SelectWithSpinAndCustomMaxTag } from "@src/components/select/SelectWithCustomMaxTag";
import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import React from "react";
import { InputSkeletonLayout } from "../../layout/input-skeleton-layout";
import { useLoadAbExploration } from "@src/hooks/ab-testing/use-ab-exploration-get-result";
import { useDebounce } from "@src/hooks/use-debounce";
import { config } from "@src/config";
import { FilterOperator, SupportedModelAlias } from "@src/constant/ab-testing/ad-testing-exploration.enum";

export const VariantFilter: React.FC<{ form: FormInstance }> = ({ form }) => {
  const abaIdWatch = Form.useWatch(FilterFormFieldsEnum.ABA_ID, form);
  const productCodeWatch = Form.useWatch(FilterFormFieldsEnum.PRODUCT_CODE, form);
  const abaIdDebounce = useDebounce(abaIdWatch, config.DEBOUNCE_TIME.SHORT_DELAY);
  const isHasAbaId = abaIdDebounce && (typeof abaIdDebounce === "string" || typeof abaIdDebounce === "number");
  const isReady = productCodeWatch && isHasAbaId;

  const { result, isLoading } = useLoadAbExploration({
    productCode: productCodeWatch,
    modelAlias: SupportedModelAlias.USER_INFO,
    query: {
      dimensions: ["exp_group"],
      filters: [
        {
          member: "aba_id",
          operator: FilterOperator.EQUALS,
          values: abaIdDebounce ? [abaIdDebounce.toString()] : [],
        },
      ].filter((f) => f.values && f.values.length > 0, []),
      limit: 10000,
      order: [["exp_group", "asc"]],
    },
    isSkip: !isReady,
  });

  const options = React.useMemo(() => {
    return (
      result?.map((item: any) => {
        return {
          label: item["exp_group"],
          value: item["exp_group"],
        };
      }) ?? []
    );
  }, [result]);

  React.useEffect(() => {
    if (!form.getFieldValue(FilterFormFieldsEnum.IS_ABA_ID_CHANGED)) return;
    form.setFieldValue(
      FilterFormFieldsEnum.VARIANTS,
      options?.map((item: any) => item.value),
    );
  }, [options]);

  React.useEffect(() => {
    if (isHasAbaId) return;
    form.setFieldsValue({ [FilterFormFieldsEnum.VARIANTS]: [] });
  }, [isHasAbaId]);

  return (
    <InputSkeletonLayout width={300} form={form} isLoading={isLoading} label="Variants" placeholder="Select Variant">
      <Form.Item label="Variants" name={FilterFormFieldsEnum.VARIANTS}>
        <SelectWithSpinAndCustomMaxTag
          disabled={!isReady}
          mode="multiple"
          allowClear
          placeholder="Select Variant"
          options={isLoading || !isHasAbaId ? [] : options}
          style={{
            width: 300,
          }}
        />
      </Form.Item>
    </InputSkeletonLayout>
  );
};

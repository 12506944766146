import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { ImageField, useTable } from "@refinedev/antd";
import { BaseRecord, useDelete, useNavigation } from "@refinedev/core";
import { mappingExpTypeToLabel } from "@src/constant/explore/mapping-title-new-explore";
import { useAppContext } from "@src/contexts/app-context";
import { Button, Form, Popconfirm, Space, Table, Tag, Tooltip, Typography } from "antd";
import React from "react";
import { DuplicateButton } from "./component/duplicate-button";
import { dayjsBrowserTimezone } from "@src/util/dayjs-browser-timezone";
import { Link } from "react-router-dom";
import { FormInstance } from "antd/lib/form";

export const ExploreList: React.FC<{ form: FormInstance<any> }> = ({ form }) => {
  const { push } = useNavigation();
  const { products, isProductLoading } = useAppContext();

  const formLoading = Form.useWatch("form_loading", form);

  const getProduct = (product_code: string) => {
    return products.find((product) => product.productCode === product_code);
  };

  const { tableProps, tableQueryResult, current, pageSize } = useTable({
    resource: "exploration",
    pagination: {
      pageSize: 10,
    },
    queryOptions: {
      enabled: !isProductLoading,
    },
    syncWithLocation: false,
  });

  const { mutateAsync: deleteExplore } = useDelete();

  const handleDelete = async (id: any) => {
    form?.setFieldValue("form_loading", true);
    await deleteExplore({
      id,
      resource: `exploration/fb-exploration`,
      successNotification: () => {
        return {
          type: "success",
          message: "Delete firebase exploration successfully!",
        };
      },
      errorNotification: () => {
        return {
          type: "error",
          message: "Delete firebase exploration failed!",
        };
      },
    });
    form?.setFieldValue("form_loading", false);
    tableQueryResult.refetch();
  };

  const dataSource = React.useMemo(() => {
    return tableQueryResult.data?.data?.data?.map((item: any, index: number) => {
      return {
        stt: (current - 1) * pageSize + index,
        ...item,
        type: mappingExpTypeToLabel[item.exp_type],
        product: {
          ...getProduct(item.product_code),
        },
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableQueryResult.data?.data, current, pageSize]);

  const columns: any[] = React.useMemo(() => {
    return [
      {
        title: "Name",
        dataIndex: "title",
        key: "title",
        filterSearch: true,
        fixed: "left",
        render: (value: string, record: any) => {
          return (
            <Tooltip title={value} className="w-[200px]" placement="topLeft">
              <Typography.Paragraph
                onClick={(e) => e.stopPropagation()}
                ellipsis={{
                  rows: 2,
                  expandable: false,
                }}
              >
                <Link to={`/explorations/firebase/edit/${record?.id}`}>{value || "-"}</Link>
              </Typography.Paragraph>
            </Tooltip>
          );
        },
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        render: (value: string) => {
          return <Tag color="blue">{value}</Tag>;
        },
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        width: "20%",
        render: (value: string) => {
          return (
            <Tooltip title={value} className="w-[200px]" placement="topLeft">
              <Typography.Paragraph
                ellipsis={{
                  rows: 2,
                  expandable: false,
                }}
              >
                {value || "-"}
              </Typography.Paragraph>
            </Tooltip>
          );
        },
      },
      {
        title: "Product",
        dataIndex: "product",
        render: (_: any, record: any) => {
          const product = record.product;
          return (
            <div className="flex items-center space-x-2">
              <ImageField value={product?.icon} width={25} height={25} preview={false} />
              <span>{product?.name}</span>
            </div>
          );
        },
      },
      {
        title: "Created At",
        dataIndex: "created_datetime",
        key: "created_datetime",
        render: (value: number) => {
          return <span>{dayjsBrowserTimezone({ value: value })}</span>;
        },
      },
      {
        title: "Updated At",
        dataIndex: "updated_datetime",
        key: "updated_datetime",
        render: (value: number) => {
          return <span>{dayjsBrowserTimezone({ value: value })}</span>;
        },
      },

      {
        title: "Actions",
        dataIndex: "actions",
        fixed: "right",
        render: (_: any, record: BaseRecord) => {
          return (
            <Space className="w-full" onClick={(e) => e.stopPropagation()}>
              <Tooltip title="Edit">
                <Button
                  icon={<EditOutlined />}
                  size="small"
                  onClick={() => {
                    push(`/explorations/firebase/edit/${record.id}`);
                  }}
                />
              </Tooltip>
              <DuplicateButton id={record.id} form={form} />

              <Tooltip title="Delete">
                <Popconfirm
                  title="Are you sure delete this exploration?"
                  onConfirm={() => {
                    handleDelete(record.id);
                  }}
                >
                  <Button danger icon={<DeleteOutlined />} size="small" />
                </Popconfirm>
              </Tooltip>
            </Space>
          );
        },
      },
    ];
  }, [dataSource]);

  return (
    <Table
      {...tableProps}
      loading={isProductLoading || tableQueryResult.isLoading || tableQueryResult.isFetching || !!formLoading}
      rowKey={"id"}
      columns={columns}
      onRow={(record) => {
        return {
          onClick: () => {
            push(`/explorations/firebase/edit/${record.id}`);
          },
        };
      }}
      scroll={{ x: "max-content", y: "calc(100vh - 150px)" }}
      dataSource={dataSource}
      pagination={{
        ...tableProps.pagination,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50, 100],
        total: tableQueryResult.data?.data?.total ?? 0,
      }}
    />
  );
};

import { HttpError, useCustomMutation } from "@refinedev/core";
import { useState, useEffect } from "react";
import { ILoadResponse } from "@src/contexts/exploration-v2/types";
import { config } from "@src/config";
import { IJobMeta, IModelAlias, IQuery, IQueryOptions } from "@src/types/ab-testing-exploration";

type LoadAbExplorationProps = {
  productCode?: string;
  modelAlias: IModelAlias;
  query: IQuery;
  options?: IQueryOptions;
  refineOptions?: {
    dataProviderName?: string;
  };
  isSkip?: boolean;
};

export const useLoadAbExploration = ({
  productCode,
  modelAlias,
  query,
  options = {
    // TODO: get form value instead
    cohort_date: "first_signal_date",
  },
  refineOptions,
  isSkip = false,
}: LoadAbExplorationProps) => {
  // const [result, setResult] = useState<Record<string, any>[]>([]);
  const [loadResponse, setLoadResponse] = useState<ILoadResponse>();
  const [result, setResult] = useState<Record<string, any>[]>();
  const [renderedQuery, setRenderedQuery] = useState<string>();
  const [resultMeta, setResultMeta] = useState<IJobMeta>();
  const [elapsedMillis, setElapsedMillis] = useState<number>();
  const [executionError, setExecutionError] = useState<string>();

  const { mutateAsync, isLoading, error } = useCustomMutation<ILoadResponse, HttpError>({
    mutationOptions: {
      onSettled(data, error) {
        if (error) {
          return;
        }
        setLoadResponse(data?.data);
      },
    },
  });

  async function fetch() {
    if (!isSkip) {
      mutateAsync({
        ...refineOptions,
        url: `${config.BACKEND_URL}/exploration_v2/ab-test-exploration/${productCode}/${modelAlias}/load`,
        method: "post",
        values: {
          query,
          options,
        },
      });
    }
  }

  useEffect(() => {
    if (productCode) {
      // TODO: enhance document
      fetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCode, modelAlias, isSkip, JSON.stringify(query), JSON.stringify(options)]);

  useEffect(() => {
    if (productCode && loadResponse) {
      setResult(loadResponse.data);
      setResultMeta(loadResponse.meta);
      setElapsedMillis(loadResponse.elapsed_millis);
      setExecutionError(loadResponse.ex);
      setRenderedQuery(loadResponse.rendered_sql);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCode, modelAlias, loadResponse]);

  return {
    isLoading,
    result,
    meta: resultMeta,
    elapsed: elapsedMillis,
    executionError,
    renderedQuery,
    httpError: error,
    refetch: fetch,
  };
};

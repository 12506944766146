import React from "react";

// types
import { IMemberTime } from "@src/types/query-builder";

// helpers
import { mapMemberToTreeNode } from "@src/helpers/tree-select-member";

// components
import MemberGroupBase from "./member-group-base";
import TimeGroupITem from "../time-group-item";
import MemberTagBase from "./member-tag-base";
import { QueryMemberGroupTimeDimensionProps } from "@src/types/query-builder-firebase";
import { convertQueryOrderToArray } from "@src/util/convert-query-order-to-array";
import { TimeDimension } from "@cubejs-client/core";

function MemberGroupTime({
  title,
  description = "Select item to display",
  availableMembers,
  members,
  updateMethods,
  updateQuery,
  query,
  isFetchingMeta,
}: QueryMemberGroupTimeDimensionProps) {
  const queryOrder = React.useMemo(() => convertQueryOrderToArray(query.order), [query.order]);
  const cubOptions = React.useMemo(() => mapMemberToTreeNode(availableMembers), [availableMembers]);
  const getDefaultCheckedKeys = React.useMemo(
    () => (query?.timeDimensions ? query.timeDimensions.map((m: any) => m.dimension) : []),
    [query.timeDimensions],
  );

  function onRemoveMember(member: IMemberTime) {
    // update query order
    const indexTimeInOrder = queryOrder.findIndex((order: any) => order[0] === member.dimension.name);
    if (indexTimeInOrder > -1) {
      queryOrder.splice(indexTimeInOrder, 1);
      setTimeout(() => {
        updateQuery({
          order: queryOrder,
        });
      });
    }

    // update query time
    updateMethods?.remove(member);
  }

  function addMembers(selectedMembers: React.Key[], membersUnSelected: React.Key[]) {
    const newOrders = queryOrder.filter((order: any) => !membersUnSelected.includes(order[0])); // update order when unselected member

    // update query time
    const timeSelected: IMemberTime[] = selectedMembers.reduce((acc, curItem) => {
      const memberItem = availableMembers.find((m) => m.name === curItem);
      acc.push(memberItem as never);
      return acc;
    }, []);
    const queryTime: TimeDimension[] = query?.timeDimensions || [];
    const timeDimensions = timeSelected.map((m) => {
      const timeItem = queryTime.find((q) => q.dimension === m.name);
      return {
        dimension: m.name,
        dateRange: timeItem?.dateRange || "Last 7 days",
        granularity: timeItem?.granularity || undefined,
      };
    });

    updateQuery({
      order: newOrders,
      timeDimensions,
    });
  }

  return (
    <MemberGroupBase
      title={title}
      dataSource={cubOptions}
      query={query}
      isFetchingMeta={isFetchingMeta}
      getDefaultCheckedKeys={getDefaultCheckedKeys}
      onOk={(selectedMembers: React.Key[], membersUnSelected: React.Key[]) => {
        addMembers(selectedMembers, membersUnSelected);
      }}
    >
      <div className="flex flex-wrap">
        {members.length > 0 ? (
          <>
            {members.map((member: any) => {
              return (
                <div key={member.dimension.name} className="flex items-center flex-wrap">
                  <div className="mb-2 mr-2">
                    <MemberTagBase member={member} onRemoveMember={onRemoveMember} />
                  </div>
                  <div className="flex items-center mb-2 ml-2">
                    <TimeGroupITem member={member} updateMethods={updateMethods} />
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <>{description}</>
        )}
      </div>
    </MemberGroupBase>
  );
}

export default MemberGroupTime;

import { FormInstance } from "antd/lib/form";
import { BaseVariant, MaxDayDiffFrom, MetricTimeRange } from "./component/general-fields";
import { MetricPerformanceTabs } from "./component/metric-performance-tabs";
import { MetricPerformanceContextProvider } from "@src/contexts/ab-testing/metric-performance-context";
import { MetricStoreTabItem } from "./component/store-visual/metric-store-tab-item";
import { css } from "@emotion/react";

export const MetricPerformance: React.FC<{ form: FormInstance<any> }> = ({ form }) => {
  return (
    <div
      css={css`
        .ant-tabs-tab-remove {
          display: none;
        }
      `}
    >
      <div className="flex justify-between">
        <MetricTimeRange form={form} />
        <MaxDayDiffFrom form={form} />
      </div>
      <BaseVariant form={form} />
      <MetricPerformanceContextProvider form={form}>
        <MetricPerformanceTabs />
      </MetricPerformanceContextProvider>
      <MetricStoreTabItem form={form} />
    </div>
  );
};

import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form } from "antd";
import { FormInstance } from "antd/lib/form";
import React from "react";
import { BaseSelectExtraField } from "./base-select-extra-field";
import { useGetOptionExtraField } from "@src/hooks/ab-testing/use-get-option-extra-field";

export const DeviceCategory: React.FC<{ form: FormInstance }> = ({ form }) => {
  const { isLoading, options } = useGetOptionExtraField({
    dimension: "device_category",
    fieldName: FilterFormFieldsEnum.EXTRA_DEVICE_CATEGORY,
    form,
  });
  return (
    <Form.Item
      className="w-[50%] max-w-[600px]"
      labelCol={{
        span: 6,
      }}
      name={FilterFormFieldsEnum.EXTRA_DEVICE_CATEGORY}
      label="Device Category"
    >
      <BaseSelectExtraField loading={isLoading} placeholder="Device Category" options={options} />
    </Form.Item>
  );
};
